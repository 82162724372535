import React from 'react';
import JCVoucher from '../JCRPVoucher/JCVoucher';

const PaymentVoucher = () => {
    return (
        <JCVoucher
            Type={7}
            Title="Payment Voucher"
        />
    );
};

export default PaymentVoucher;
