import React from 'react'
import SCForm from './SCForm'

const Customer = () => {
  return (
    <SCForm Title="Customer" parentId="9" parentName="Sundry Debtors" />
  )
}

export default Customer
