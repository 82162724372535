import React, { useState } from "react";
import configContext from "./configContext";
import { toast } from "react-toastify";
import { mainHost } from "../config.js";

const ConfigState = (props) => {
    const [dueDates, setDueDates] = useState([]);
    const [voucher, setVoucher] = useState([]);
    const [tax, setTax] = useState([]);
    const [currency, setCurrency] = useState("");
    const [totalRecords, setTotalRecords] = useState(0);

    // Get DueDate Dropdown
    const getDueDateDD = async () => {
        try {
            const response = await fetch(`${mainHost}/Config/GeDueDateDD`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            const data = await response.json();
            setDueDates(data.dueDates);
        } catch (error) {
            console.error("Some error occurred", error);
            throw error;
        }
    };

    // Get DueDate Dropdown
    const getTaxDD = async () => {
        try {
            const response = await fetch(`${mainHost}/Config/GetTaxDD`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            const data = await response.json();
            setTax(data.taxes);
        } catch (error) {
            console.error("Some error occurred", error);
            throw error;
        }
    };

    //Get Invoice Number
    const getInvoiceNumber = async (type) => {
        try {
            const response = await fetch(
                `${mainHost}/Config/GetInvoiceNumber?type=${type}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            const data = await response.json();
            return data.invoiceNumber;
        } catch (error) {
            console.error("Some error occurred", error);
            throw error;
        }
    };

    // Save Voucher
    const saveVoucher = async (requestBody) => {
        try {
            const response = await fetch(`${mainHost}/Config/SaveSPVoucher`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json",
                    // "X-Form-Name": formName,
                },
                body: JSON.stringify(requestBody),
            });
            const data = await response.json();
            if (data.success) {
                toast.success(data.message);
            } else {
                toast.error(data.message);
            }
            return data.success;
        } catch (error) {
            console.error("Registration failed:", error);
            throw error;
        }
    };

    //Get Voucher
    const getVoucher = async (type, pageNumber, limit, search) => {
        try {
            const response = await fetch(
                `${mainHost}/Config/GetVoucher?type=${type}&pageNumber=${pageNumber}&limit=${limit}&search=${search}`,
                {
                    // const response = await fetch(`${mainHost}/Config/GetVoucher?pageNumber=${pageNumber}&limit=${limit}&search=${search}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            const data = await response.json();

            if (data.success) {
                const formattedVoucher = data.voucher.map((voucher) => {
                    const formattedInvoiceNumber = voucher.invoiceNumber
                        .toString()
                        .padStart(5, "0");
                    const formattedDueDate = new Date(voucher.dueDate).toLocaleDateString(
                        "en-US",
                        {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                        }
                    );
                    const formattedDate = new Date(voucher.date).toLocaleDateString(
                        "en-US",
                        {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                        }
                    );
                    return {
                        ...voucher,
                        invoiceNumber: formattedInvoiceNumber,
                        dueDate: formattedDueDate,
                        date: formattedDate,
                    };
                });
                setVoucher(formattedVoucher);
                setTotalRecords(data.totalRecords);
            } else {
                setVoucher([]);
                setTotalRecords(0);
            }
        } catch (error) {
            console.error("Some error occurred", error);
            throw error;
        }
    };

    // Save Voucher
    const deleteVoucher = async (id, Type) => {
        try {
            const response = await fetch(
                `${mainHost}/Config/DeleteSPVoucher?id=${id}&type=${Type}`,
                {
                    method: "PUT",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            const data = await response.json();
            if (data.success) {
                getVoucher(1, 5, "");
                toast.success(data.message);
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            console.error("Registration failed:", error);
            throw error;
        }
    };

    // Get Currency
    const getCurrency = async () => {
        try {
            const response = await fetch(`${mainHost}/Config/GetCurrency`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            const data = await response.json();
            setCurrency(data.currency);
        } catch (error) {
            console.error("Some error occurred", error);
            throw error;
        }
    };

    const postApi = async (formName, formData, endPoint) => {
        try {
            const response = await fetch(`${mainHost}/${endPoint}`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "X-Form-Name": formName,
                },
                body: formData,
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Operation failed:", error);
            throw error;
        }
    };
    const putApi = async (formName, formData, endPoint) => {
        try {
            const response = await fetch(`${mainHost}/${endPoint}`, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "X-Form-Name": formName,
                },
                body: formData,
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Operation failed:", error);
            throw error;
        }
    };

    const deleteApi = async (endPoint) => {
        try {
            const response = await fetch(`${mainHost}/${endPoint}`, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Operation failed:", error);
            throw error;
        }
    };

    const postApi_NoForm = async (formName, requestBody, endPoint) => {
        try {
            const response = await fetch(`${mainHost}/${endPoint}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "X-Form-Name": formName,
                },
                body: JSON.stringify(requestBody),
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Operation failed:", error);
            throw error;
        }
    };

    const getApi = async (formName, endPoint) => {
        try {
            const response = await fetch(`${mainHost}/${endPoint}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "X-Form-Name": formName,
                },
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Some error occurred", error);
            throw error;
        }
    };

    //Get Voucher
    const getJVVoucher = async (type, pageNumber, limit, search) => {
        try {
            const response = await fetch(
                `${mainHost}/Voucher/GetVoucher?type=${type}&pageNumber=${pageNumber}&limit=${limit}&search=${search}`,
                {
                    // const response = await fetch(`${mainHost}/Config/GetVoucher?pageNumber=${pageNumber}&limit=${limit}&search=${search}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            const data = await response.json();

            if (data.success) {
                const formattedVoucher = data.voucher.map((voucher) => {
                    const formattedVoucherNo = voucher.voucherNo
                        .toString()
                        .padStart(5, "0");
                    const entryDate = new Date(voucher.entryDate);
                    const currentDate = new Date(voucher.currentDate);
                    const formattedEntryDate = `${entryDate.getFullYear()}-${String(
                        entryDate.getMonth() + 1
                    ).padStart(2, "0")}-${String(entryDate.getDate()).padStart(2, "0")}`;
                    const formattedCurrentDate = `${currentDate.getFullYear()}-${String(
                        currentDate.getMonth() + 1
                    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(
                        2,
                        "0"
                    )}`;
                    return {
                        ...voucher,
                        voucherNo: formattedVoucherNo,
                        entryDate: formattedEntryDate,
                        currentDate: formattedCurrentDate,
                    };
                });
                setVoucher(formattedVoucher);
                setTotalRecords(data.totalRecords);
            } else {
                setVoucher([]);
                setTotalRecords(0);
            }
        } catch (error) {
            console.error("Some error occurred", error);
            throw error;
        }
    };

    const deleteJVVoucher = async (id, Type) => {
        try {
            const response = await fetch(
                `${mainHost}/Voucher/DeleteJVVoucher?id=${id}&type=${Type}`,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            const data = await response.json();
            if (data.success) {
                getJVVoucher(1, 5, "");
                toast.success(data.message);
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            console.error("Registration failed:", error);
            throw error;
        }
    };

    return (
        <configContext.Provider
            value={{
                getDueDateDD,
                dueDates,
                getInvoiceNumber,
                getTaxDD,
                tax,
                saveVoucher,
                getVoucher,
                voucher,
                deleteVoucher,
                getCurrency,
                currency,                
                postApi,
                getApi,
                postApi_NoForm,
                getJVVoucher,
                deleteJVVoucher,
                deleteApi,
                putApi,
            }}
        >
            {props.children}
        </configContext.Provider>
    );
};
export default ConfigState;
