import React from 'react';
import TextField from '@mui/material/TextField';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const NumberTextField = ({ value, ...rest }) => {
  const formattedValue = formatter.format(value);

  return (
    <TextField
      type="text"
      value={formattedValue}
      {...rest}
    />
  );
};
export default NumberTextField;