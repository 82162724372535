import { Box, TextField, Button } from '@mui/material';
import { Col, Container, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { InputMask } from 'primereact/inputmask';
import { useEffect } from 'react';

const AddSC = ({ values, setValues, handleChange, show, handleClose, title, handleSubmit, parentName, handleShow, editRecord }) => {
    useEffect(() => {
        if (editRecord) {
            setValues({
                ...values,
                accountId: editRecord.accountId,
                accountName: editRecord.accountName,
                accountPrefix: editRecord.accountPrefix,
                accountContactNumber: editRecord.accountContactNumber,
                accountAddress: editRecord.accountAddress,
                parentId: editRecord.parentId,
                accountEmailAddress: editRecord.accountEmailAddress,
                accountCode: editRecord.accountCode
            })
            handleShow();
        }
    }, [editRecord])

    return (
        <>
            <Button variant="contained" onClick={handleShow}>Add {title}</Button>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add {title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Box component="form" onSubmit={handleSubmit}
                            noValidate autoComplete="off" >
                            <input type="hidden" value={values.accountId} />

                            <Row className='mb-3'>
                                <Col className='mx-3'>
                                    <Row className='mb-3'>
                                        <label htmlFor="parentId">Account Group Name</label>
                                        <TextField type="text" value={parentName}
                                            size='medium' disabled={true} />
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='mx-3'>
                                    <Row className='mb-3'>
                                        <label htmlFor="accountName">Account Name</label>
                                        <TextField type="text" value={values.accountName}
                                            size='medium' onChange={(e)=>handleChange(e, "accountName")} />
                                    </Row>
                                </Col>

                                <Col className='mx-3'>
                                    <Row className='mb-3'>
                                        <label htmlFor="accountPrefix">Account Prefix</label>
                                        <TextField type="text" value={values.accountPrefix}
                                            size='medium' onChange={(e)=>handleChange(e, "accountPrefix")} />
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='mx-3'>
                                    <Row className='mb-3'>
                                        <label htmlFor="accountContactNumber">Account Phone Number</label>
                                        <InputMask
                                            mask="9999-9999999"
                                            value={values.accountContactNumber}
                                            onChange={(e)=>handleChange(e, "accountContactNumber")}

                                        >
                                            {(inputProps) => (
                                                <TextField
                                                    label="Account Phone Number"
                                                    type="text"
                                                    value={values.accountContactNumber}

                                                    {...inputProps}
                                                />
                                            )}
                                        </InputMask>
                                    </Row>
                                </Col>

                                <Col className='mx-3'>
                                    <Row className='mb-3'>
                                        <label htmlFor="accountAddress">Account Address</label>
                                        <TextField
                                            multiline
                                            value={values.accountAddress}
                                            onChange={(e)=>handleChange(e, "accountAddress")}
                                        />
                                    </Row>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col className='mx-3'>
                                    <Row className='mb-3'>
                                        <label htmlFor="parentId">Account Email Address</label>
                                        <TextField type="email" value={values.accountEmailAddress}
                                            size='medium' onChange={(e)=>handleChange(e, "accountEmailAddress")} />
                                    </Row>
                                </Col>
                            </Row>
                            <Row className='m-3 text-center'>
                                <Button type='submit' variant="contained">Save</Button>
                            </Row>
                        </Box>
                    </Container>
                </Modal.Body >
            </Modal >
        </>
    )
}

export default AddSC
