import React, { useContext, useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import userContext from '../../services/user/userContext';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
  const { menuItems } = useContext(userContext);
  const [openMenuId, setOpenMenuId] = useState(null);
  const location = useLocation();

  const handleClick = (menuId) => {
    setOpenMenuId((prevOpenMenuId) => (prevOpenMenuId === menuId ? null : menuId));
  };   

  return (
    <List style={{ backgroundColor: '#0d6efd' }}>
      {menuItems.map((item) => {
        if (item.menuUrl === null) {
          const isMainDropdownActive = menuItems
            .filter((subItem) => subItem.parentId === item.menuId)
            .some((subItem) => location.pathname === subItem.menuUrl);

          return (
            <React.Fragment key={item.menuId}>
              <ListItem button onClick={() => handleClick(item.menuId)} style={{ color: 'white' }}>
                <ListItemIcon style={{ color: 'white' }}>
                  <i className={item.menuIcon} />
                </ListItemIcon>
                <ListItemText primary={item.menuText} />
                {openMenuId === item.menuId ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openMenuId === item.menuId} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {menuItems
                    .filter((subItem) => subItem.parentId === item.menuId)
                    .map((subItem) => (
                      <ListItem
                        key={subItem.menuId}
                        button
                        component={Link}
                        to={subItem.menuUrl}
                        selected={location.pathname === subItem.menuUrl}
                        style={{ paddingLeft: '40px', color: 'white' }} 
                      >
                        <ListItemIcon style={{ color: 'white' }}>
                          <i className={subItem.menuIcon} /> 
                        </ListItemIcon>
                        <ListItemText primary={subItem.menuText} />
                      </ListItem>
                    ))}
                </List>
              </Collapse>
            </React.Fragment>
          );
        } else if (item.parentId === 0) {
          return (
            <ListItem
              key={item.menuId}
              button
              component={Link}
              to={item.menuUrl}
              selected={location.pathname === item.menuUrl}
              style={{ color: 'white' }}
            >
              <ListItemIcon style={{ color: 'white' }}>
                <i className={item.menuIcon} />
              </ListItemIcon>
              <ListItemText primary={item.menuText} />
            </ListItem>
          );
        }
        return null;
      })}
    </List>
  );
};

export default Sidebar;
