import React from 'react'
import CompanyList from './CompanyList';

const Company = () => {
    return (
        <>
            <CompanyList />
        </>
    )
}

export default Company