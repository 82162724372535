import React from 'react'
import SPVoucher from '../SPVoucher/SPVoucher'

const PurchaseOrder = () => {
  return (
    <div>
       <SPVoucher Title="Sale Order voucher" Type={4}  />
    </div>
  )
}

export default PurchaseOrder
