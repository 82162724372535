import { Box, Button } from "@mui/material";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import configContext from "../../../services/congiguration/configContext";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { useLocation } from "react-router-dom";

function AddTax(props) {
  const { editRecord, setRefresh, refresh } = props;
  const { postApi } = useContext(configContext);
  const location = useLocation();
  const InitialState = {
    TaxId: "",
    TaxName: "",
    TaxPercentage: "",
  };

  const [values, setValues] = useState(InitialState);

  const anyValueIsUnfilled = Object.entries(values).some(([key, value]) => {
    if (key === "TaxId" && value === "") {
      values.TaxId = "0";
      return false;
    }
    return value === "" || value === null;
  });

  const resetForm = () => {
    setValues(InitialState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(values);
    if (!anyValueIsUnfilled) {
      const formData = new FormData();
      formData.append("TaxId", values.TaxId);
      formData.append("TaxName", values.TaxName);
      formData.append("TaxPercentage", values.TaxPercentage);
      const data = await postApi(location.pathname, formData, "Tax/AddTax");
      if (data.success) {
        toast.success(data.message);
        resetForm();
        setRefresh(!refresh);
      } else {
        toast.error(data.message);
      }
    } else {
      toast.error("Please fill all required details");
    }
  };

  useEffect(() => {
    if (editRecord != null) {
      setValues({
        TaxId: editRecord.taxId,
        TaxName: editRecord.taxName,
        TaxPercentage: editRecord.taxPercentage,
      });
    }
  }, [editRecord]);

  return (
    <>
      <Container>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
          className="my-5"
        >
          <InputText type="text" value={values.TaxId} size="medium" hidden />
          <Row className="mb-3">
            <div className="p-inputgroup flex-1">
              <InputText
                className="p-inputtext-lg"
                value={values.TaxName}
                placeholder="Tax Name"
                onChange={(e) =>
                  setValues({ ...values, TaxName: e.target.value })
                }
              />
            </div>
          </Row>
          <Row className="mb-3">
            <div className="p-inputgroup flex-1">
              <InputNumber
                className="p-inputtext-lg"
                value={values.TaxPercentage}
                placeholder="Tax Percentage"
                onChange={(e) =>
                  setValues({ ...values, TaxPercentage: e.value })
                }
               />
              <span className="p-inputgroup-addon">%</span>
            </div>
          </Row>
          <Row className="m-3 text-center">
            <Button type="submit" variant="contained">
              Save
            </Button>
            <Button
              variant="contained"
              className="mt-3"
              onClick={() => resetForm()}
            >
              Clear
            </Button>
          </Row>
        </Box>
      </Container>
    </>
  );
}

export default AddTax;
