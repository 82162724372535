import { Box, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { InputMask } from "primereact/inputmask";
import { toast } from 'react-toastify';
import configContext from '../../../services/congiguration/configContext';
import { useLocation } from 'react-router-dom';

function AddUser(props) {
    const { addOrEdit, editRecord, modaltitle, setModalTitle } = props;
    const { getApi } = useContext(configContext);
    const location = useLocation()
    const [rolesDD, setRolesDD] = useState([])
    const [show, setShow] = useState(false);
    const handleClose = () => resetForm();
    const handleShow = () => setShow(true);

    const fetchData = async () => {
        const data = await getApi(location.pathname, "Role/GetRolesDD")
        if (data.success) {
            setRolesDD(data.roles);
        }
    }
    useEffect(() => {
        fetchData();
    }, []);

    const InitialState = {
        BranchId: '',
        UserId: '',
        EmailAddress: '',
        Password: '',
        Username: '',
        PhoneNumber: '',
        roleId: '',
    };

    const [values, setValues] = useState(InitialState);

    const anyValueIsUnfilled = Object.entries(values).some(([key, value]) => {
        if (key === 'UserId' && value === '') {
            values.UserId = '0';
            return false;
        }
        return value === '' || value === null;
    });

    const resetForm = () => {
        setValues(InitialState);
        setShow(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(values)
        if (!anyValueIsUnfilled) {
            const formData = new FormData();
            formData.append('UserId', values.UserId);
            formData.append('BranchId', values.BranchId);
            formData.append('EmailAddress', values.EmailAddress);
            formData.append('Username', values.Username);
            formData.append('PhoneNumber', values.PhoneNumber);
            formData.append('Password', values.Password);
            formData.append('roleId', values.roleId);

            addOrEdit(formData, resetForm);
        } else {
            toast.error('Please fill all required details');
        }

    };

    useEffect(() => {
        if (editRecord != null) {
            setValues({
                UserId: editRecord.userId,
                BranchId: editRecord.branchId,
                EmailAddress: editRecord.emailAddress,
                Username: editRecord.userName,
                PhoneNumber: editRecord.phoneNumber,
                roleId: editRecord.roleId,
                Password: ''
            })
            setModalTitle('Edit User')
            handleShow();
        }
    }, [editRecord]);

    const handleAddUser = () => {
        setModalTitle('Add User')
        handleShow();
    };

    return (
        <>
            <Button variant="contained" onClick={handleAddUser}>
                Add User
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{modaltitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Box component="form" onSubmit={handleSubmit}
                            noValidate autoComplete="off" >
                            <TextField
                                type="text"
                                value={values.UserId}
                                size='medium'
                                hidden
                            />
                            <Row>
                                {/* <Col className='mx-3'>
                                    <Row className='mb-3'>
                                        <label htmlFor="BranchId">Select Branch</label>
                                        <FormControl fullWidth>
                                            <Select
                                                value={values.BranchId}
                                                onChange={(e) => setValues({ ...values, BranchId: e.target.value })}
                                                placeholder='Select a Branch'
                                            >
                                                {branchDD && branchDD.length > 0 ? (
                                                    branchDD.map((branch) => (
                                                        <MenuItem key={branch.branchId} value={branch.branchId} >
                                                            <img height={18} width={30} src={branch.logo} />
                                                            <span className='mx-2'>{branch.userName}</span>
                                                        </MenuItem>
                                                    ))
                                                ) : (
                                                    <MenuItem>No Branch Registered in this Company</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Row>
                                </Col> */}
                                <Col className='mx-3'>
                                    <Row className='mb-3'>
                                        <label htmlFor="rolesId">Select Role</label>
                                        <FormControl fullWidth>
                                            <Select
                                                value={values.roleId}
                                                onChange={(e) => setValues({ ...values, roleId: e.target.value })}
                                                placeholder='Select a Role'
                                            >
                                                {rolesDD && rolesDD.length > 0 ? (
                                                    rolesDD.map((roles) => (
                                                        <MenuItem key={roles.roleId} value={roles.roleId} >
                                                            <span className='mx-2'>{roles.role}</span>
                                                        </MenuItem>
                                                    ))
                                                ) : (
                                                    <MenuItem>No Roles Registered in this Company</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='mx-3'>
                                    <Row className='mb-3'>
                                        <label htmlFor="CPassword">User Name</label>
                                        <TextField
                                            type="text"
                                            value={values.Username}
                                            size='medium'
                                            onChange={(e) =>
                                                setValues({ ...values, Username: e.target.value })
                                            }
                                        />
                                    </Row>
                                </Col>
                                <Col className='mx-3'>
                                    <Row className='mb-3'>
                                        <label htmlFor="PhoneNumber">Phone Number</label>
                                        <InputMask
                                            mask="9999-9999999"
                                            value={values.PhoneNumber}
                                            onChange={(e) => setValues({ ...values, PhoneNumber: e.target.value })}

                                        >
                                            {(inputProps) => (
                                                <TextField
                                                    label="Phone Number"
                                                    type="text"
                                                    value={values.PhoneNumber}

                                                    {...inputProps}
                                                />
                                            )}
                                        </InputMask>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col className='mx-3'>
                                    <Row className='mb-3'>
                                        <label htmlFor="Email">Email Address</label>
                                        <TextField type="email" value={values.EmailAddress}
                                            size='medium' onChange={(e) => setValues({ ...values, EmailAddress: e.target.value })} />
                                    </Row>
                                </Col>

                                <Col className='mx-3'>
                                    <Row className='mb-3'>
                                        <label htmlFor="Password">Password</label>
                                        <TextField
                                            type="password"
                                            value={values.Password}
                                            size='medium'
                                            onChange={(e) => setValues({ ...values, Password: e.target.value })}
                                        />
                                    </Row>
                                </Col>
                            </Row>

                            <Row className='m-3 text-center'>
                                <Button type='submit' variant="contained">Save</Button>
                            </Row>
                        </Box>
                    </Container>
                </Modal.Body >
            </Modal >
        </>
    );
}

export default AddUser;