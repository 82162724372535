import React, { useContext, useState, useCallback, useEffect } from 'react';
import { Container, Modal } from 'react-bootstrap';
import { Button } from '@mui/material';
import configContext from '../../services/congiguration/configContext';
import Pagination from '../../listComponents/Pagination';
import DeleteConfirmation from './../basic/DeleteConfirmation';
import { useNavigate } from 'react-router-dom';

const SPVoucherList = ({ setEditRecord, Type, name }) => {
    const navigate = useNavigate();
    const { voucher, getVoucher, deleteVoucher, totalRecords } = useContext(configContext);
    const [modaltitle, setModalTitle] = useState('Sale List');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteVoucherId, setdeleteVoucherId] = useState(null);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const columns = [
        { field: 'invoiceNumber', header: 'Invoice Number' },
        { field: 'accountName', header: 'Account' },
        { field: 'dueDate', header: 'Due Date' },
        { field: 'grandTotal', header: 'Grand Total' }
    ];

    const onEditClick = (rowData) => {
        setEditRecord(rowData);
        handleClose();
    };
    
    const onDeleteClick = useCallback((rowData) => {
        setdeleteVoucherId(rowData.masterId);
        setShowDeleteModal(true);
    }, []);

    const handleDelete = useCallback(async () => {
        console.log(deleteVoucherId);
        await deleteVoucher(deleteVoucherId, Type);
        setShowDeleteModal(false);
        setdeleteVoucherId(null);
    }, [deleteVoucher, deleteVoucherId]);

    return (
        <>
            <Button variant="contained" onClick={handleShow}>
                {name}
            </Button>
            <Modal
                show={show}
                onHide={handleClose}
                size='xl'
            >
                <Modal.Header closeButton>
                    <Modal.Title>{modaltitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card my-2">
                        <Pagination
                            data={voucher}
                            columns={columns}
                            datafunction={getVoucher}
                            totalrecords={totalRecords}
                            onEdit={onEditClick}
                            onDelete={onDeleteClick}
                            dataid='masterId'
                            image={false}
                            Type={Type}
                        />
                    </div>
                </Modal.Body >
            </Modal >
            {showDeleteModal && (
                <DeleteConfirmation
                    item='Voucher'
                    handleClose={() => setShowDeleteModal(false)}
                    handleDelete={handleDelete}
                />
            )}
        </>
    )
}

export default SPVoucherList
