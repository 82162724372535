import React from 'react'
import BranchList from './BranchList'

const Branch = () => {
  return (
    <>
      <BranchList/>
    </>
  )
}

export default Branch
