import React, { useContext, useState, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@mui/material';
import configContext from '../../services/congiguration/configContext';
import Pagination from './../../listComponents/Pagination';
import DeleteConfirmation from './../basic/DeleteConfirmation';

const JVList = ({ setEditRecord, Type, modaltitle }) => {
    
    const { voucher, getJVVoucher, totalRecords, deleteJVVoucher } = useContext(configContext);
    
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteVoucherId, setDeleteVoucherId] = useState(null);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const columns = [
        { field: 'voucherNo', header: 'Voucher Number' },
        { field: 'accountName', header: 'Account' },
        { field: 'entryDate', header: 'Entry Date' },
        { field: 'totalAmount', header: 'Total Amount' }
    ];

    const onEditClick = (rowData) => {
        setEditRecord(rowData);
        handleClose();
    };
    
    const onDeleteClick = useCallback((rowData) => {
        setDeleteVoucherId(rowData.voucherId);
        setShowDeleteModal(true);
    }, []);

    const handleDelete = useCallback(async () => {
        console.log(deleteVoucherId);
        const data = await deleteJVVoucher(deleteVoucherId, Type);
        setShowDeleteModal(false);
        setDeleteVoucherId(null);
    }, [deleteVoucherId]);

    return (
        <>
            <Button variant="contained" onClick={handleShow}>
               List
            </Button>
            <Modal
                show={show}
                onHide={handleClose}
                size='xl'
            >
                <Modal.Header closeButton>
                    <Modal.Title>{modaltitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card my-2">
                        <Pagination
                            data={voucher}
                            columns={columns}
                            datafunction={getJVVoucher}
                            totalrecords={totalRecords}
                            onEdit={onEditClick}
                            onDelete={onDeleteClick}
                            dataid='masterId'
                            image={false}
                            Type={Type}
                        />
                    </div>
                </Modal.Body >
            </Modal >
            {showDeleteModal && (
                <DeleteConfirmation
                    item='Voucher'
                    handleClose={() => setShowDeleteModal(false)}
                    handleDelete={handleDelete}
                />
            )}
        </>
    )
}

export default JVList
