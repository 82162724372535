import React, { useEffect, useState } from 'react';
import userContext from './userContext';
import { toast } from 'react-toastify';
import {mainHost} from '../config.js'

function formatDate(dateStr) {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
};
const UserState = (props) => {
    const host = mainHost + '/User';
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [menuItems, setMenuItems] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [companyDD, setCompanyDD] = useState([]);
    const [branchDD, setBranchDD] = useState([]);
    const [branches, setBranches] = useState([]);
    const [users, setUsers] = useState([]);
    const [currencyDD, setCurrencyDD] = useState([]);
    const [logo, setLogo] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    

    useEffect(() => {
        if (localStorage.getItem('logo')) {
            setLogo(localStorage.getItem('logo'))
        }
        if (localStorage.getItem('token')) {
            setIsLoggedIn(true);
        }
    }, [])
    useEffect(() => {
        if (localStorage.getItem('token')) {
            getMenu();
        }
    }, []);

    // Login
    const login = async (credentials) => {
        try {
            const response = await fetch(`${host}/AccountLogin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(credentials),
            });
            const data = await response.json();
            if (data.success) {
                setIsLoggedIn(true);
                toast.success(data.message);
                localStorage.setItem('token', data.token);
                localStorage.setItem('logo', data.logo);
                setIsLoggedIn(true);
                setLogo(data.logo);
                getMenu();
            } else {
                toast.error(data.message);
            }
            return data.success;
        } catch (error) {
            console.error('Login failed:', error);
            toast.error('Internal Server Failure');
            throw error;
        }
    };

    const logout = async () => {
        setIsLoggedIn(false);
        localStorage.removeItem('token');
        localStorage.removeItem('logo');
    }

    const getMenu = async () => {
        try {
            const response = await fetch(`${host}/GetAccMenu`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();            
            setMenuItems(data);
            return data;
        } catch (error) {
            console.error('Some error occured', error);
            throw error;
        }
    }

    // Register Company
    const registerCompany = async (formData) => {
        try {
            const response = await fetch(`${host}/CreateCompany`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: formData,
            });
            const data = await response.json();
            if (data.success) {
                toast.success(data.message);
                getCompanies(1, 5, '');
            }
            else {
                toast.error(data.message);
            }
            return data.success;
        } catch (error) {
            console.error('Registration failed:', error);
            throw error;
        }
    };

    // Get Companies
    const getCompanies = async (pageNumber, limit, searchQuery) => {
        try {
            const response = await fetch(`${host}/GetAllCompanies?pageNumber=${pageNumber}&limit=${limit}&search=${searchQuery}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const responseData = await response.json();
            const updatedCompanies = responseData.companies.map(company => ({
                ...company,
                expiry: formatDate(company.expiry)
            }));
            setCompanies(updatedCompanies);
            setTotalRecords(responseData.totalRecords);
        } catch (error) {
            console.error('Some error occurred', error);
            throw error;
        }
    }

    // Edit Company
    const editCompany = async (id, formData) => {
        try {
            const response = await fetch(`${host}/EditCompany?id=${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: formData
            });
            const data = await response.json();
            if (data.success) {
                toast.success(data.message);
                getCompanies(1, 5, '');
            }
            else {
                toast.error(data.message);
            }
            return data.success;
        } catch (error) {
            console.error('Registration failed:', error);
            throw error;
        }
    };

    // Delete Company
    const DeleteCompany = async (id) => {
        try {
            const response = await fetch(`${host}/CompanyDeactivate?id=${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                }
            });
            const data = await response.json();
            if (data.success) {
                toast.success(data.message);
                getCompanies(1, 5, '');
            }
            else {
                toast.error(data.message);
            }
        } catch (error) {
            console.error('Deletion failed:', error);
            throw error;
        }
    };

    // Get Company Dropdown
    const getCompanyDropdown = async () => {
        try {
            const response = await fetch(`${host}/CompanyDropdown`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const responseData = await response.json();
            setCompanyDD(responseData.companies);
        } catch (error) {
            console.error('Some error occured', error);
            throw error;
        }
    };

    // Get Currency Dropdown
    const getCurrencyDropdown = async () => {
        try {
            const response = await fetch(`${host}/CurrencyDropdown`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setCurrencyDD(data.currency);
        } catch (error) {
            console.error('Some error occured', error);
            throw error;
        }
    };

    // Register Branch
    const registerBranch = async (formData) => {
        try {
            const response = await fetch(`${host}/CreateBranch`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: formData,
            });
            const data = await response.json();
            console.log(data)
            if (data.success) {
                toast.success(data.message);
                getBranches(1, 5, '');
            }
            else {
                toast.error(data.message);
            }
            return data.success;
        } catch (error) {
            console.error('Registration failed:', error);
            throw error;
        }
    };

    // Get All Branches
    const getBranches = async (pageNumber, limit, searchQuery) => {
        try {
            const response = await fetch(`${host}/GetAllBranch?pageNumber=${pageNumber}&limit=${limit}&search=${searchQuery}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const responseData = await response.json();
            const updatedBranches = responseData.branch.map(branch => ({
                ...branch,
                expiry: formatDate(branch.expiry)
            }));
            setBranches(updatedBranches);
            setTotalRecords(responseData.totalRecords);
        } catch (error) {
            console.error('Some error occurred', error);
            throw error;
        }
    }

    //Edit Branch
    const editBranch = async (id, formData) => {
        try {
            const response = await fetch(`${host}/EditBranch?id=${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: formData
            });
            const data = await response.json();
            if (data.success) {
                toast.success(data.message);
                getBranches(1, 5, '');
            }
            else {
                toast.error(data.message);
            }
            return data.success;
        } catch (error) {
            console.error('Registration failed:', error);
            throw error;
        }
    };

    // Delete Brach
    const deleteBranch = async (id) => {
        try {
            const response = await fetch(`${host}/BranchDeactivate?id=${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                }
            });
            const responseData = await response.json();
            if (responseData.success) {
                getBranches(1, 5, '');
                toast.success(responseData.message);
            }
            else {
                toast.error(responseData.message);
            }
        } catch (error) {
            console.error('Deletion failed:', error);
            throw error;
        }
    };

    // Get Branch Dropdown
    const getBranchDropdown = async () => {
        try {
            const response = await fetch(`${host}/BranchDropdown`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const responseData = await response.json();
            setBranchDD(responseData.branches);
        } catch (error) {
            console.error('Some error occured', error);
            throw error;
        }
    };

    // Get All Users
    const getUsers = async (pageNumber, limit, searchQuery) => {
        try {
            const response = await fetch(`${host}/GetAllUsers?pageNumber=${pageNumber}&limit=${limit}&search=${searchQuery}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const responseData = await response.json();
            setUsers(responseData.data);
            setTotalRecords(responseData.totalRecords);
        } catch (error) {
            console.error('Some error occurred', error);
            throw error;
        }
    }

    // Register user
    const registerUser = async (formData) => {
        try {
            const response = await fetch(`${host}/AccountRegister`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: formData,
            });
            const data = await response.json();
            if (data.success) {
                toast.success(data.message);
                getUsers(1, 5, '');
            }
            else {
                toast.error(data.message);
            }
            return data.success;
        } catch (error) {
            console.error('Registration failed:', error);
            throw error;
        }
    };

    //Edit User
    const editUser = async (id, formData) => {
        try {
            const response = await fetch(`${host}/EditUser?id=${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: formData
            });
            const data = await response.json();
            if (data.success) {
                toast.success(data.message);
                getUsers(1, 5, '');
            }
            else {
                toast.error(data.message);
            }
            return data.success;
        } catch (error) {
            console.error('Updation failed:', error);
            throw error;
        }
    };

    //Delete User
    const deleteUser = async (id) => {
        try {
            const response = await fetch(`${host}/AccountDeactivate?id=${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            if (data.success) {
                toast.success(data.message);
                getUsers(1, 5, '');
            }
            else {
                toast.error(data.message);
            }
            return data.success;
        } catch (error) {
            console.error('Updation failed:', error);
            throw error;
        }
    };


    return (
        <userContext.Provider value={{
            login,
            registerCompany,
            isLoggedIn,
            logout,
            menuItems,
            logo,
            getCompanies,
            companies,
            setCompanies,
            editCompany,
            DeleteCompany,
            registerBranch,
            getCompanyDropdown,
            companyDD,
            branches,
            getBranches,
            editBranch,
            deleteBranch,
            totalRecords,
            getUsers,
            users,
            branchDD,
            getBranchDropdown,
            registerUser,
            editUser,
            deleteUser,
            getMenu,
            currencyDD,
            getCurrencyDropdown
        }}>
            {props.children}
        </userContext.Provider>
    );
};

export default UserState;