import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { Doughnut } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { useContext } from "react";
import configContext from "../../services/congiguration/configContext";
import { useLocation } from "react-router-dom";

Chart.register(CategoryScale);

const Home = () => {
  const { getApi } = useContext(configContext);
  const location = useLocation();
  const [totalSaleValue, setTotalSaleValue] = useState(0);
  const [totalPurchaseValue, setTotalPurchaseValue] = useState(0);
  const [totalUsersValue, setTotalUsersValue] = useState(0);
  const [monthName, setMonthName] = useState([]);
  const [recordCount, setRecordCount] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      console.log()
      const data = await getApi(location.pathname,"Dashboard/GetDashboard");
      if (data.success) {
        setTotalSaleValue(data.data.totalSale);
        setTotalPurchaseValue(data.data.totalPurchase);
        setTotalUsersValue(data.data.totalUsers);
        setMonthName(data.data.monthName);
        setRecordCount(data.data.recordCount);
        console.log(data.data);
      }
    };
    fetchData();
  }, []);

  const [totalSale, setTotalSale] = useState(0);
  const [totalPurchase, setTotalPurchase] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  const [saleAnimationComplete, setSaleAnimationComplete] = useState(false);
  const [purchaseAnimationComplete, setPurchaseAnimationComplete] =
    useState(false);
  const [usersAnimationComplete, setUsersAnimationComplete] = useState(false);

  // Example chart data
  const chartData = {
    labels: monthName,
    datasets: [
      {
        label: "Sales",
        fill: false,
        lineTension: 0.5,
        backgroundColor: "rgba(75,192,192,1)",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 2,
        data: recordCount,
      },
    ],
  };

  const doughnutChartData = {
    labels: ["Sale", "Purchase", "Users"],
    datasets: [
      {
        data: [totalSale, totalPurchase, totalUsers],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };
  const doughnutChartOptions = {
    cutoutPercentage: 60,
  };
  const doughnutChartContainerStyle = {
    height: "270px",
  };

  useEffect(() => {
    const updateTotal = (
      prevTotal,
      totalValue,
      setTotal,
      setAnimationComplete,
      interval
    ) => {
      return () => {
        const newTotal = prevTotal + 1;
        setTotal(newTotal >= totalValue ? totalValue : newTotal);
        if (newTotal === totalValue) {
          setAnimationComplete(true);
          clearInterval(interval);
        }
      };
    };

    let saleInterval, purchaseInterval, usersInterval;

    if (!saleAnimationComplete) {
      saleInterval = setInterval(
        updateTotal(
          totalSale,
          totalSaleValue,
          setTotalSale,
          setSaleAnimationComplete,
          saleInterval
        ),
        10
      );
    }

    if (!purchaseAnimationComplete) {
      purchaseInterval = setInterval(
        updateTotal(
          totalPurchase,
          totalPurchaseValue,
          setTotalPurchase,
          setPurchaseAnimationComplete,
          purchaseInterval
        ),
        10
      );
    }

    if (!usersAnimationComplete) {
      usersInterval = setInterval(
        updateTotal(
          totalUsers,
          totalUsersValue,
          setTotalUsers,
          setUsersAnimationComplete,
          usersInterval
        ),
        10
      );
    }

    return () => {
      clearInterval(saleInterval);
      clearInterval(purchaseInterval);
      clearInterval(usersInterval);
    };
  }, [
    totalSale,
    totalSaleValue,
    totalPurchase,
    totalPurchaseValue,
    totalUsers,
    totalUsersValue,
    saleAnimationComplete,
    purchaseAnimationComplete,
    usersAnimationComplete,
  ]);

  return (
    <Grid container spacing={3} className="my-3">
      {/* Charts Container */}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          {/* Chart 1: Line Chart */}
          <Grid item xs={6}>
            <Paper elevation={3} style={{ padding: 20 }}>
              <Typography variant="h6">Sales Trend</Typography>
              <Line data={chartData} />
            </Paper>
          </Grid>

          {/* Chart 2: Doughnut Chart */}
          <Grid item xs={6}>
            <Paper elevation={3} style={{ padding: 20 }}>
              <Typography variant="h6">Distribution</Typography>
              <div style={doughnutChartContainerStyle}>
                <Doughnut
                  data={doughnutChartData}
                  options={doughnutChartOptions}
                />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      {/* Total Sale */}
      <Grid item xs={4}>
        <Paper elevation={3} style={{ padding: 20 }}>
          <Typography variant="h6">Total Sale</Typography>
          <Typography variant="h4">{totalSale}</Typography>
        </Paper>
      </Grid>

      {/* Total Purchase */}
      <Grid item xs={4}>
        <Paper elevation={3} style={{ padding: 20 }}>
          <Typography variant="h6">Total Purchase</Typography>
          <Typography variant="h4">{totalPurchase}</Typography>
        </Paper>
      </Grid>

      {/* Total Users */}
      <Grid item xs={4}>
        <Paper elevation={3} style={{ padding: 20 }}>
          <Typography variant="h6">Total Users</Typography>
          <Typography variant="h4">{totalUsers}</Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Home;
