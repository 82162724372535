import React, { useContext, useState, useCallback, useEffect } from 'react';
import { Row, Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import AddAccount from './AddAccount';
import DeleteConfirmation from './../../basic/DeleteConfirmation';
import configContext from './../../../services/congiguration/configContext';
import CustomTreeView from '../../../listComponents/CustomTreeView';
import { ProgressSpinner } from 'primereact/progressspinner';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

const AccountList = () => {
    const { putApi, getApi } = useContext(configContext);
    const [editRecord, setEditRecord] = useState(null);
    const location = useLocation();
    const [modaltitle, setModalTitle] = useState('Add Item');
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteAccountsId, setdeleteAccountsId] = useState(null);
    const [accounts, setAccounts] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const data = await getApi(location.pathname, "Accounts/GetAccounts")
            if (data.success) {
                setAccounts(data.accounts);
            }
        }
        fetchData();
    }, [])

    const onEditClick = (value) => {
        setModalTitle('Edit Account')
        const data = accounts.find(account => account.accountId === value);
        setEditRecord(data)
    };

    const onDeleteClick = useCallback((rowData) => {
        setdeleteAccountsId(rowData);
        setShowDeleteModal(true);
    }, []);

    const handleDelete = useCallback(async () => {

        const formData = new FormData();
        formData.append('id', deleteAccountsId);
        const data = await putApi(location.pathname, formData, "Accounts/DeleteAccount")
        if (data.success) {
            toast.success(data.message);
        } else {
            toast.error(data.message);
        }
        setShowDeleteModal(false);
        setRefresh(!refresh);
        setdeleteAccountsId(null);
    }, [deleteAccountsId]);

    const handleAdd = (value) => {
        setModalTitle('Add Account')
        setEditRecord({
            ...editRecord,
            parentId: value === "0" ? "0" :
                (accounts.find((a) => a.accountId === value)).accountId,
            accountId: "0",
            accountName: "",
            accountContactNumber: "",
            accountAddress: "",
        })
    }
    // useEffect(() => {
    //     const fetchData = async () => {
    //         await getAccountDDAC();
    //     }
    //     fetchData();
    //     setLoading(false)
    // }, [editRecord])
    if (loading) {
        return <div className="m-5 p-5 d-flex justify-content-center">
            <ProgressSpinner />
        </div>;
    }
    return (
        <>
            <Container fluid>
                <Row className='my-3 mx-2'>
                    <Card>
                        <Card.Header as='h5' className="d-flex justify-content-between align-accounts-center">
                            <span className="mb-0">Accounts Data</span>
                            <AddAccount editRecord={editRecord} modaltitle={modaltitle}
                                refresh={refresh} setRefresh={setRefresh} />
                        </Card.Header>
                        <Card.Body>
                            <Container >
                                <Row className='my-3 mx-2'>
                                    <Card>
                                        <Card.Header as='h5'>List Of Registered Accounts</Card.Header>
                                        <Card.Body>
                                            <div className="card my-2">
                                                <CustomTreeView
                                                    dataField={"accounts"}
                                                    dataFunction={"Accounts/GetAccounts"}
                                                    idProperty={"accountId"}
                                                    labelProperty={"accountName"}
                                                    addData={handleAdd}
                                                    updateData={onEditClick}
                                                    deleteData={onDeleteClick}
                                                    refresh={refresh}
                                                />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Row>

            </Container>
            {showDeleteModal && (
                <DeleteConfirmation
                    item='Account'
                    handleClose={() => setShowDeleteModal(false)}
                    handleDelete={handleDelete}
                />
            )}
        </>
    )
}

export default AccountList
