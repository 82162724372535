import './App.css';
import { Routes, Route } from 'react-router-dom';
import Header from './components/basic/Header';
import Home from './components/basic/Home';
import About from './components/basic/About';
import AccountLogin from './components/loginComponent/AccountLogin';
import Branch from './components/Masters/Branch/Branch';
import Company from './components/Masters/Company/Company';
import User from './components/Masters/User/User';
import UnitList from './components/Masters/Unit/UnitList';
import ItemList from './components/Masters/Item/ItemList';
import SearchBar from './listComponents/SearchBar';
import AccountList from './components/Masters/Account/AccountList';
import RoleList from './components/Masters/Menu/RoleList';
import ReceiptVoucher from './components/JVRPVoucher/ReciptVoucher';
import PaymentVoucher from './components/JVRPVoucher/PaymentVoucher';
import ContraVoucher from './components/JVRPVoucher/ContraVoucher';
import JournalVoucher from './components/JVRPVoucher/JournalVoucher';
import AddSale from './components/Sale/AddSale';
import AddPurchase from './components/Purchase/AddPurchase';
import SalesOrder from './components/Sale/SalesOrder';
import PurchaseOrder from './components/Purchase/PurchaseOrder';
import Supplier from './components/Masters/Supplier_Customer/Supplier';
import Customer from './components/Masters/Supplier_Customer/Customer';
import { Col, Row } from 'react-bootstrap';
import Sidebar from './components/Dasboard/Sidebar';
import { useContext } from 'react';
import userContext from './services/user/userContext';
import TaxList from './components/Masters/Tax/TaxList';


function App() {
  const { isLoggedIn, logout, menuItems, logo } = useContext(userContext);
  return (

    <>
      {!isLoggedIn ? (
        <AccountLogin />
      ) : (
        <>
          <Row>
            <Row>
              <Header />
            </Row>
            <Row style={{ height: '87dvh' }}>
              <Col md={2} style={{ backgroundColor: '#0d6efd' }}>
                <Sidebar />
              </Col>
              <Col md={10}>
                <Routes>
                  <Route exact index element={<Home />} />
                  <Route exact path='/About' element={<About />} />
                  <Route exact path='/Home' element={<Home />} />
                  <Route exact path='/About' element={<About />} />
                  <Route exact path='/Company' element={<Company />} />
                  <Route exact path='/Branch' element={<Branch />} />
                  <Route exact path='/User' element={<User />} />
                  <Route exact path='/Role' element={<RoleList />} />
                  <Route exact path='/Unit' element={<UnitList />} />
                  <Route exact path='/Tax' element={<TaxList />} />
                  <Route exact path='/Item' element={<ItemList />} />
                  <Route exact path='/test' element={<SearchBar />} />
                  <Route exact path='/Sale' element={<AddSale />} />
                  <Route exact path='/Purchase' element={<AddPurchase />} />
                  <Route exact path='/Account' element={<AccountList />} />
                  <Route exact path='/SaleOrder' element={<SalesOrder />} />
                  <Route exact path='/PurchaseOrder' element={<PurchaseOrder />} />
                  <Route exact path='/JournalVoucher' element={<JournalVoucher />} />
                  <Route exact path='/ContraVoucher' element={<ContraVoucher />} />
                  <Route exact path='/PaymentVoucher' element={<PaymentVoucher />} />
                  <Route exact path='/ReceiptVoucher' element={<ReceiptVoucher />} />
                  <Route exact path='/Supplier' element={<Supplier />} />
                  <Route exact path='/Customer' element={<Customer />} />
                </Routes>
              </Col>
            </Row>
          </Row>

        </>
      )}
    </>
  );
}

export default App;


