import React from 'react'
import SPVoucher from '../SPVoucher/SPVoucher'

const AddPurchase = () => {
  return (
    <div>
      <SPVoucher Title="Purchase voucher" Type={2}  />
    </div>
  )
}

export default AddPurchase
