import { InputText } from 'primereact/inputtext'
import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import CustomAutoCompleteNew from '../../listComponents/CustomAutoCompleteNew'

const MasterHeader = ({ masterData, handleChange, Type, handleMasterAccountChange, rpAccounts }) => {
    return (
        <Card.Body>
            <input type="hidden" value={masterData.voucherId} />
            <Row className='mx-2 mt-3'>
                <Col className='mx-3'>
                    <Row className='mb-3'>
                        <label>Voucher Number</label>
                        <InputText
                            value={masterData.voucherNo}
                            readOnly
                            style={{ backgroundColor: "#e5dcdc" }}
                        />
                    </Row>
                </Col>

                <Col className='mx-3'>
                    <Row className='mb-3'>
                        <label>Transaction number</label>
                        <InputText
                            value={masterData.transNo}
                            readOnly
                            style={{ backgroundColor: "#e5dcdc" }}
                        />
                    </Row>
                </Col>
            </Row>
            <Row className='mx-2'>
                <Col className='mx-3'>
                    <Row className='mb-3'>
                        <label>Posted From</label>
                        <InputText
                            value={masterData.postedFrom}
                            id="postedFrom"
                            onChange={(e) => handleChange(e, "postedFrom")}
                        />
                    </Row>
                </Col>


                <Col className='mx-3'>
                    <Row className='mb-3'>
                        <label>Posted Entry No</label>
                        <InputText
                            readOnly
                            style={{ backgroundColor: "#e5dcdc" }}
                            value={masterData.postedEntryNo}
                        />
                    </Row>
                </Col>
            </Row>

            <Row className='mx-2'>
                <Col className='mx-3'>
                    <Row className='mb-3'>
                        <label>Entry Date</label>
                        <InputText
                            value={masterData.entryDate}
                            type="date"
                            id="dueDate"
                            onChange={(e) => handleChange(e, "entryDate")}
                        />
                    </Row>
                </Col>
                <Col className='mx-3'>
                    <Row className='mb-3'>
                        <label>Remarks</label>
                        <InputText
                            value={masterData.remarks}
                            onChange={(e) => handleChange(e, "remarks")}
                        />
                    </Row>
                </Col>
            </Row>
            {Type === 7 || Type === 8 ? (
                <Row className='mx-2'>
                    <Col className='mx-1'>
                        <Row className='mb-3'>
                            <label>Transactional Account</label>
                            <CustomAutoCompleteNew
                                dataFunction={"Voucher/GetAccounts?Type=6"}
                                data={rpAccounts}
                                value={masterData.accountId}
                                setter={handleMasterAccountChange}
                                idProperty={'accountId'}
                                labelProperty={'accountName'}
                            />
                        </Row>
                    </Col>
                </Row>
            ) : (<></>)}
        </Card.Body>
    )
}

export default MasterHeader
