import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Row, Container, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import DeleteConfirmation from "./../../basic/DeleteConfirmation";
import AddUnit from "./AddUnit";
import configContext from "./../../../services/congiguration/configContext";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const UnitList = () => {
  const { postApi, getApi, putApi } = useContext(configContext);
  const [units, setUnits] = useState([]);
  const location = useLocation();
  const [editRecord, setEditRecord] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUnits, setFilteredUnits] = useState([]);
  useEffect(() => {
    const fetchUnits = async () => {
      const data = await getApi(location.pathname, "Units/GetUnits");
      if (data.success) {
        setUnits(data.units);
      }
    };
    fetchUnits();
  }, [refresh]);
  useEffect(() => {
    const filtered = units.filter((unit) =>
      unit.unitName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredUnits(filtered);
  }, [searchQuery, units]);

  const onDeleteClick = useCallback((rowData) => {
    setDeleteItemId(rowData.unitId);
    setShowDeleteModal(true);
  }, []);

  const handleDelete = async () => {
    const formData = new FormData();
    formData.append("id", deleteItemId);
    const data = await putApi(location.pathname, formData, "Units/DeleteUnit");
    if (data.success) {
      toast.success(data.message);
      setRefresh(!refresh);
    } else {
      toast.error(data.message);
    }
    setShowDeleteModal(false);
    setDeleteItemId(null);
  };
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  const header = () => {
    return (
      <div className="p-input-icon-left" style={{ marginBottom: "10px" }}>
        <i className="pi pi-search" />
        <InputText
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>
    );
  };
  const rowEditorBodyTemplate = useMemo(
    () => (rowData) => {
      return (
        <div>
          <Button
            type="button"
            rounded
            text
            icon="pi pi-pencil"
            onClick={() => setEditRecord(rowData)}
            raised
            size="small"
            className="mx-2"
          />

          <Button
            type="button"
            icon="pi pi-trash"
            rounded
            text
            severity="danger"
            raised
            size="small"
            onClick={() => onDeleteClick(rowData)}
          />
        </div>
      );
    },
    []
  );
  return (
    <>
      <Container fluid>
        <Row className="my-3 mx-2">
          <Card>
            <Card.Header
              as="h5"
              className="d-flex justify-content-between align-items-center"
            >
              <span className="mb-0">Unit Data</span>
            </Card.Header>
            <Card.Body>
              <Container fluid>
                <Row className="my-3 mx-3">
                  <Col className="mx-3">
                    <Card>
                      <Card.Header as="h5">Add Units</Card.Header>
                      <Card.Body>
                        <AddUnit
                          editRecord={editRecord}
                          postApi={postApi}
                          refresh={refresh}
                          setRefresh={setRefresh}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col className="mx-3">
                    <Card>
                      <Card.Header as="h5">
                        List Of Registered Units
                      </Card.Header>
                      <Card.Body>
                        <div className="card my-2">
                          <DataTable
                            value={filteredUnits}
                            header={header}
                            editMode="row"
                            paginator
                            rows={5}
                            dataKey="unitId"
                          >
                            <Column
                              field="serialNumber"
                              header="S.No"
                              body={(rowData) =>
                                filteredUnits.indexOf(rowData) + 1
                              }
                            />
                            <Column field="unitName" header="Unit" sortable />
                            <Column
                              header="Action"
                              body={rowEditorBodyTemplate}
                            ></Column>
                          </DataTable>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Row>
      </Container>
      {showDeleteModal && (
        <DeleteConfirmation
          item="Unit"
          handleClose={() => setShowDeleteModal(false)}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
};

export default UnitList;
