import { Button } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Card, Container, Row } from 'react-bootstrap'
import { toast } from "react-toastify";
import { ProgressSpinner } from 'primereact/progressspinner'
import MasterHeader from './MasterHeader'
import JCChild from './JCChild'
import JCMasterFooter from './JCMasterFooter'
import configContext from '../../services/congiguration/configContext';
import JVList from './JVList';
import { useLocation } from 'react-router-dom';


const InitialChildData = {
    voucherChildId: "",
    voucherId: "",
    accountCode: "",
    accountName: "",
    accountId: "",
    type: "",
    amount: "",
    narration: "",
};
const initialMasterData = {
    voucherId: "0",
    transNo: "0",
    entryDate: "",
    postedFrom: "0",
    voucherNo: "",
    postedEntryNo: "0",
    remarks: "",
    totalAmount: "0",
    totalCr: "",
    totalDr: "",
    accountName: "",
    accountId: "",
};

const JCVoucher = ({ Type, Title }) => {
    const { getApi, currency, getCurrency, postApi_NoForm } = useContext(configContext)
    const [childData, setChildData] = useState(InitialChildData)
    const [masterData, setMasterData] = useState(initialMasterData)
    const [editIndex, setEditIndex] = useState(-1);
    const [listItems, setListItems] = useState([]);
    const [editRecord, setEditRecord] = useState("");
    const [CrDrs, setCrDrs] = useState([]);
    const [rpAccounts, setRpAccounts] = useState([]);
    const [accountsDD, setAccountsDD] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation()

    useEffect(() => {
        if (editRecord) {
            setListItems(editRecord.childData);
            setMasterData({
                voucherId: editRecord.voucherId,
                transNo: editRecord.transNo,
                entryDate: editRecord.entryDate,
                postedFrom: editRecord.postedFrom,
                voucherNo: editRecord.voucherNo,
                postedEntryNo: editRecord.postedEntryNo,
                remarks: editRecord.remarks,
                totalCr: editRecord.totalAmount,
                totalDr: editRecord.totalAmount,
                ...(Type === 5 || Type === 6 ? {} : { accountId: (rpAccounts.find((a) => a.accountId === editRecord.accountId)).accountId }),
            });
        }
    }, [editRecord, Type, rpAccounts]);
    

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = await getApi(location.pathname, `Voucher/GetVoucherNumber?type=${Type}`);
            setMasterData({ ...masterData, voucherNo: data.voucherNumber })
            const data3 = await getApi(location.pathname, "Voucher/GetCrDr");
            setCrDrs(data3.crDr)
            setChildData({ ...childData, type: data3.crDr[0] })
            const data4 = await getApi(location.pathname, "Voucher/GetTransAccounts");
            setRpAccounts(data4.accounts)
            const data5 = await getApi(location.pathname, `Voucher/GetAccounts?Type=6`);
            setAccountsDD(data5.accounts)
            await getCurrency();
            setLoading(false);
        }
        fetchData();
    }, []);

    const handleChange = (e, field) => {
        setMasterData({
            ...masterData,
            [field]: e.target.value
        })
    };

    const handleAccountChange = (newValue) => {
        setChildData((prevState) => ({
            ...prevState,
            accountId: newValue?.accountId || "",
            accountName: newValue?.accountName || "",
            accountCode: newValue?.accountCode || "",
        }));
    };

    const handleMasterAccountChange = (newValue) => {
        setMasterData((prevState) => ({
            ...prevState,
            accountId: newValue?.accountId
        }));
    };

    const anyValueIsUnfilled = () => {
        return Object.entries(childData).some(([key, value]) => {
            if (key === "voucherChildId" || key === "voucherId") {
                return false;
            }
            return value === "" || value === null || value === 0;
        });
    };

    const handleChildAdd = () => {
        if (editIndex === -1) {
            if (!anyValueIsUnfilled()) {
                const newItem = {
                    accountCode: childData.accountCode,
                    accountId: childData.accountId,
                    accountName: childData.accountName,
                    type: childData.type,
                    amount: childData.amount,
                    narration: childData.narration,
                };
                setListItems((prevState) => [...prevState, newItem]);
                setChildData(InitialChildData);
            } else {
                toast.error("Please fill Account and amount!");
            }
        } else {
            const updatedListItems = [...listItems];
            updatedListItems[editIndex] = {
                accountCode: childData.accountCode,
                accountId: childData.accountId,
                accountName: childData.accountName,
                type: childData.type,
                amount: childData.amount,
                narration: childData.narration,
            };
            setListItems(updatedListItems);
            setEditIndex(-1);
            setChildData(InitialChildData);
        }
        setTimeout(() => {
            const itemInput = document.getElementById("itemInput");
            if (itemInput) {
                itemInput.focus();
            }
        }, 100);
    };

    const handleChildEdit = (index) => {
        setEditIndex(index);
        const selectedItem = listItems[index];
        setChildData({
            accountCode: selectedItem.accountCode,
            accountId: selectedItem.accountId,
            accountName: selectedItem.accountName,
            type: selectedItem.type,
            amount: selectedItem.amount,
            narration: selectedItem.narration,
        });
    };

    const handleChildDelete = (index) => {
        const updatedListItems = [...listItems];
        updatedListItems.splice(index, 1);
        setListItems(updatedListItems);
    };

    const resetForm = async () => {
        setMasterData(initialMasterData);
        setChildData(InitialChildData);
        setListItems([]);
        const data = await getApi(`Voucher/GetVoucherNumber?type=${Type}`);
        setMasterData({ voucherNo: data.voucherNumber })
    }
    useEffect(() => {
        const updatedMasterData = { ...masterData, totalDr: 0, totalCr: 0 };
        listItems.forEach(item => {
            const amount = parseInt(item.amount, 10) || 0;
            if (Type === 7 || Type === 8) {
                updatedMasterData.totalCr += amount;
            } else {
                if (item.type.crDrId === 2) {
                    updatedMasterData.totalDr += amount;
                } else if (item.type.crDrId === 1) {
                    updatedMasterData.totalCr += amount;
                }
            }
        });

        setMasterData(updatedMasterData);
    }, [listItems]);

    const handleSaveVoucher = async () => {
        console.log(masterData)

        if (Type === 7 || Type === 8) {
            if (masterData.transNo === "" ||
                masterData.entryDate === "" ||
                masterData.postedFrom === "" ||
                masterData.voucherNo === "" ||
                masterData.postedEntryNo === "" ||
                masterData.remarks === "" ||
                masterData.totalCr === "" ||
                masterData.accountId === "") {
                return toast.error("Please fill all voucher details!")
            }
        }
        if (Type === 5 || Type === 6) {
            if (masterData.totalCr !== masterData.totalDr) {
                return toast.error("Total Credit amount and Debit amount is not equal!")
            }
            if (masterData.transNo === "" ||
                masterData.entryDate === "" ||
                masterData.postedFrom === "" ||
                masterData.voucherNo === "" ||
                masterData.postedEntryNo === "" ||
                masterData.remarks === "" ||
                masterData.totalCr === "" ||
                masterData.totalDr === ""
            ) {
                return toast.error("Please fill all voucher details!")
            }
        }

        setLoading(true);
        if (listItems.length > 0) {
            const childData = listItems.map(item => ({
                voucherChildId: item.voucherChildId === undefined ? 0 : item.voucherChildId,
                accountId: item.accountId,
                amount: item.amount,
                drcrId: item.type.crDrId,
                narration: item.narration,
            }));
            const masterForm =
            {
                voucherId: masterData.voucherId === "" ? 0 : masterData.voucherId,
                transNo: masterData.transNo,
                entryDate: masterData.entryDate,
                voucherNo: masterData.voucherNo,
                postedFrom: masterData.postedFrom,
                postedEntryNo: masterData.postedEntryNo,
                remarks: masterData.remarks,
                totalAmount: masterData.totalCr,
                accountId: masterData.accountId === "" ? null : masterData.accountId,
                transTypeId: Type,
                childData: childData
            };
            const data = await postApi_NoForm(masterForm, "Voucher/SaveJVVoucher");
            if (data.success) {
                toast.success(data.message)
                setLoading(false);
                resetForm();
            } else {
                toast.success(data.message)
                setLoading(false);
            }
        } else {
            setLoading(false);
            toast.error("No Item!");
        }
        setLoading(false);
    };

    if (loading) {
        return <div className="m-5 p-5 d-flex justify-content-center">
            <ProgressSpinner />
        </div>;
    }

    return (
        <Container fluid>
            <Row className='my-3 mx-2'>
                <Card>
                    <Card.Header as='h5' className="d-flex justify-content-between align-items-center">
                        <span className="mb-0">{Title}</span>
                        <div>
                            <Button className="mx-2" variant="contained" onClick={handleSaveVoucher} > Save</Button>

                            <JVList setEditRecord={setEditRecord} Type={Type} modaltitle={Title + " List"} />
                            <Button className="mx-2" variant="contained" onClick={resetForm}>New</Button>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Container >
                            <Row className='my-3 mx-2'>
                                <Card>
                                    <Card.Header as='h5'>Add {Title}</Card.Header>

                                    <MasterHeader
                                        masterData={masterData}
                                        handleChange={handleChange}
                                        Type={Type}
                                        handleMasterAccountChange={handleMasterAccountChange}
                                        rpAccounts={rpAccounts}
                                    />
                                    <JCChild
                                        childData={childData}
                                        setChildData={setChildData}
                                        accountsDD={accountsDD}
                                        handleAccountChange={handleAccountChange}
                                        CrDrs={CrDrs}
                                        handleChildAdd={handleChildAdd}
                                        editIndex={editIndex}
                                        listItems={listItems}
                                        handleChildEdit={handleChildEdit}
                                        handleChildDelete={handleChildDelete}
                                        Type={Type}
                                    />
                                </Card>
                            </Row>
                            <JCMasterFooter masterData={masterData} currency={currency} Type={Type} />
                        </Container>
                    </Card.Body>
                </Card>
            </Row>

        </Container>
    )
}

export default JCVoucher
