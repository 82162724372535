import { Box, TextField, Button, FormControlLabel, Checkbox } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { InputMask } from 'primereact/inputmask';
import configContext from '../../../services/congiguration/configContext';
import CustomAutoCompleteNew from '../../../listComponents/CustomAutoCompleteNew';
import { useLocation } from 'react-router-dom';

function AddAccount(props) {
    const { editRecord, modaltitle, setRefresh, refresh } = props;
    const { getApi, postApi } = useContext(configContext);
    const location = useLocation();
    const [accounts, setAccounts] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); resetForm(); }
    const handleShow = () => setShow(true);

    const InitialState = {
        accountId: '',
        accountName: '',
        accountPrefix: '',
        accountContactNumber: '',
        accountAddress: '',
        parentId: ''
    }
    const [values, setValues] = useState(InitialState);
    // useEffect(() => {
    //     const fetchData = async () => {
    //         const data = await getApi()
    //     }

    // }, [])

    const resetForm = () => {
        setValues(InitialState);
        setShow(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (values.parentId !== '' && values.accountName !== '') {

            const formData = new FormData();
            formData.append('accountId', values.accountId);
            formData.append('accountName', values.accountName);
            formData.append('accountPrefix', values.accountPrefix === undefined ? '' : values.accountPrefix);
            formData.append('accountContactNumber', values.accountContactNumber);
            formData.append('accountAddress', values.accountAddress);
            formData.append('parentId', values.parentId === "0" ? "" : values.parentId);

            const res = await postApi(location.pathname, formData, "Accounts/AddAccount");
            if (res.success) {
                toast.success(res.message);
                resetForm();
                setRefresh(!refresh);
            } else {
                toast.error(res.message);
            }
        } else {
            toast.error('Please fill all required details');
        }
    };

    useEffect(() => {
        if (editRecord != null) {
            console.log(editRecord)
            setValues({
                accountId: editRecord.accountId,
                accountName: editRecord.accountName,
                accountPrefix: editRecord.accountPrefix,
                accountContactNumber: editRecord.accountContactNumber,
                accountAddress: editRecord.accountAddress,
                parentId: editRecord.parentId
            })
            handleShow();
        }
    }, [editRecord]);

    const handleAccountGroupChange = (newValue) => {
        setValues({ ...values, parentId: newValue?.accountId })
    }
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{modaltitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Box component="form" onSubmit={handleSubmit}
                            noValidate autoComplete="off" >
                            <input type="hidden" value={values.accountId} />

                            <Row className='mb-3'>
                                <label htmlFor="parentId">Account Group Name</label>
                                <CustomAutoCompleteNew
                                    dataFunction={"Accounts/getAccounts"}
                                    data={accounts}
                                    value={values.parentId}
                                    setter={handleAccountGroupChange}
                                    idProperty={'accountId'}
                                    labelProperty={'accountName'}
                                    disabled={values.parentId === "0" ? false : true}
                                />
                            </Row>
                            <Row>
                                <Col className='mx-3'>
                                    <Row className='mb-3'>
                                        <label htmlFor="accountName">Account Name</label>
                                        <TextField type="text" value={values.accountName}
                                            size='medium' onChange={(e) => setValues({ ...values, accountName: e.target.value })} />
                                    </Row>
                                </Col>

                                <Col className='mx-3'>
                                    <Row className='mb-3'>
                                        <label htmlFor="accountPrefix">Account Prefix</label>
                                        <TextField type="text" value={values.accountPrefix}
                                            size='medium' onChange={(e) => setValues({ ...values, accountPrefix: e.target.value })} />
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='mx-3'>
                                    <Row className='mb-3'>
                                        <label htmlFor="accountContactNumber">Account Phone Number</label>
                                        <InputMask
                                            mask="9999-9999999"
                                            value={values.accountContactNumber}
                                            onChange={(e) => setValues({ ...values, accountContactNumber: e.target.value })}

                                        >
                                            {(inputProps) => (
                                                <TextField
                                                    label="Account Phone Number"
                                                    type="text"
                                                    value={values.accountContactNumber}

                                                    {...inputProps}
                                                />
                                            )}
                                        </InputMask>
                                    </Row>
                                </Col>

                                <Col className='mx-3'>
                                    <Row className='mb-3'>
                                        <label htmlFor="accountAddress">Account Address</label>
                                        <TextField
                                            multiline
                                            value={values.accountAddress}
                                            onChange={(e) => setValues({ ...values, accountAddress: e.target.value })}
                                        />
                                    </Row>
                                </Col>
                            </Row>

                            <Row className='m-3 text-center'>
                                <Button type='submit' variant="contained">Save</Button>
                            </Row>
                        </Box>
                    </Container>
                </Modal.Body >
            </Modal >
        </>
    );
}

export default AddAccount;
