import { InputText } from 'primereact/inputtext'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

const JCMasterFooter = ({ masterData, currency, Type }) => {
    return (
        <Row className='mx-5'>

            {(Type === 5 || Type === 6) ? (
                <Col md={{ span: 6, offset: 6 }}>
                    <Row className="mb-3">
                        <Col md={6} className="mt-3">
                            <b>
                                <label className='mb-2'>Total Credit</label>
                            </b>
                            <div className="p-inputgroup flex-1">
                                <InputText
                                    value={masterData.totalCr}
                                    readOnly
                                    style={{ backgroundColor: "#e5dcdc" }}
                                />
                                <span className="p-inputgroup-addon">{currency}</span>
                            </div>

                        </Col>
                        <Col md={6} className="mt-3">
                            <b>
                                <label className='mb-2'>Total Debit</label>
                            </b>
                            <div className="p-inputgroup flex-1">
                                <InputText
                                    value={masterData.totalDr}
                                    readOnly
                                    style={{ backgroundColor: "#e5dcdc" }}
                                />
                                <span className="p-inputgroup-addon">{currency}</span>
                            </div>

                        </Col>
                    </Row>
                </Col>
            ) : (
                <Col md={{ span: 6, offset: 9 }}>
                    <Row className="mb-3">
                        <Col md={6} className="mt-3">
                            <b>
                                <label className='mb-2'>Total Amount</label>
                            </b>
                            <div className="p-inputgroup flex-1">
                                <InputText
                                    value={masterData.totalCr}
                                    readOnly
                                    style={{ backgroundColor: "#e5dcdc" }}
                                />
                                <span className="p-inputgroup-addon">{currency}</span>
                            </div>
                        </Col>
                    </Row>
                </Col>
            )}

        </Row>
    )
}

export default JCMasterFooter
