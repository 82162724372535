import React, { useContext, useState, useCallback } from 'react';
import { Row, Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import DeleteConfirmation from './../../basic/DeleteConfirmation';
import AddRole from './AddRole';
import userContext from '../../../services/user/userContext';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import configContext from '../../../services/congiguration/configContext';
import DataTableNew from '../../../listComponents/DataTableNew';

const RoleList = () => {
    const { postApi_NoForm, putApi } = useContext(configContext)
    const location = useLocation();
    const [editRecord, setEditRecord] = useState(null);
    const [modaltitle, setModalTitle] = useState('Add Role');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [deleteRoleId, setdeleteRoleId] = useState(null);
    const columns = [
        { field: 'role', header: 'Role Name' },
    ];


    const addOrEdit = async (requestBody, onSuccess) => {
        const data = await postApi_NoForm(location.pathname, requestBody, "Role/AddRole")
        if (data.success) {
            toast.success(data.message);
            setRefresh(!refresh)
            onSuccess();
        } else {
            toast.error(data.message);
        }
    }

    const onEditClick = (rowData) => {
        setEditRecord(rowData);
    };

    const onDeleteClick = useCallback((rowData) => {
        setdeleteRoleId(rowData.roleId);
        setShowDeleteModal(true);
    }, []);

    const handleDelete = useCallback(async () => {
        const formData = new FormData();
        formData.append("id", deleteRoleId);
        const data = await putApi(location.pathname, formData, "Role/DeleteRole")
        if(data.success){
            toast.success(data.message);
            setRefresh(!refresh);
            setShowDeleteModal(false);
            setdeleteRoleId(null);
        }else{
            toast.error(data.message);
        }
        //await deleteRole(deleteRoleId);
        
    }, [putApi, deleteRoleId]);

    return (
        <>
            <Container fluid>
                <Row className='my-3 mx-2'>
                    <Card>
                        <Card.Header as='h5' className="d-flex justify-content-between align-roles-center">
                            <span className="mb-0">Role Data</span>
                            <AddRole addOrEdit={addOrEdit} editRecord={editRecord} modaltitle={modaltitle}
                                setModalTitle={setModalTitle} setEditRecord={setEditRecord} />
                        </Card.Header>
                        <Card.Body>
                            <Container >
                                <Row className='my-3 mx-2'>
                                    <Card>
                                        <Card.Header as='h5'>List Of Registered Roles</Card.Header>
                                        <Card.Body>
                                            <div className="card my-2">                                               
                                                <DataTableNew
                                                    datafunction="Role/GetRoles"
                                                    onEdit={onEditClick}
                                                    onDelete={onDeleteClick}
                                                    columns={columns}
                                                    dataid='roleId'
                                                    refresh={refresh}
                                                    dataField="roles"
                                                    countField="totalRecords"
                                                />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Row>

            </Container>
            {showDeleteModal && (
                <DeleteConfirmation
                    item='Role'
                    handleClose={() => setShowDeleteModal(false)}
                    handleDelete={handleDelete}
                />
            )}
        </>
    )
}

export default RoleList
