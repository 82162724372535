import React, { useCallback, useContext, useState } from 'react';
import { Row, Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import AddBranch from './AddBranch';
import { toast } from 'react-toastify';
import DeleteConfirmation from './../../basic/DeleteConfirmation';
import userContext from '../../../services/user/userContext';
import Pagination from '../../../listComponents/Pagination';

const BranchList = () => {
    const { branches, getBranches, editBranch, deleteBranch, registerBranch, totalRecords } = useContext(userContext);
    const [editRecord, setEditRecord] = useState(null);
    const [modaltitle, setModalTitle] = useState('Add Branch');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);

    const columns = [
        { field: 'userName', header: 'Branch Name' },
        { field: 'companyUserName', header: 'Company Name' },
        { field: 'ntn', header: 'N.T.N' },
        { field: 'expiry', header: 'Expiry' },
        { field: 'branchAddress', header: 'Branch Address' },
    ];

    const addOrEdit =  useCallback(async (formData, onSuccess) => {
        if (formData.get('BranchId') === "0") {
            const data = await registerBranch(formData);
            if (data) {
                onSuccess();
            }
        }
        else {
            const data = await editBranch(formData.get('BranchId'), formData);
            if (data) {                
                onSuccess();
            }
        }
    },[registerBranch, editBranch]);

    const onEditClick = (rowData) => {
        setEditRecord(rowData);
    };
    
    const onDeleteClick = useCallback((rowData) => {
        setDeleteItemId(rowData.branchId);
        setShowDeleteModal(true);
    }, []);

    const handleDelete = useCallback(async () => {
        await deleteBranch(deleteItemId);
        setShowDeleteModal(false);
        setDeleteItemId(null);
    }, [deleteBranch, deleteItemId]);

    return (
        <>
            <Container fluid>
                <Row className='my-3 mx-2'>
                    <Card>
                        <Card.Header as='h5' className="d-flex justify-content-between align-items-center">
                            <span className="mb-0">Branch Data</span>
                            <AddBranch addOrEdit={addOrEdit} editRecord={editRecord} modaltitle={modaltitle}
                                setModalTitle={setModalTitle} />
                        </Card.Header>
                        <Card.Body>
                            <Container >
                                <Row className='my-3 mx-2'>
                                    <Card>
                                        <Card.Header as='h5'>List Of Registered Branches</Card.Header>
                                        <Card.Body>
                                            <div className="card my-2">
                                                <Pagination
                                                    data={branches}
                                                    columns={columns}
                                                    datafunction={getBranches}
                                                    totalrecords={totalRecords}
                                                    onEdit={onEditClick}
                                                    onDelete={onDeleteClick}
                                                    dataid='branchId'
                                                    image={true}
                                                />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Row>

            </Container>
            {showDeleteModal && (
                <DeleteConfirmation
                    item='Branch'
                    handleClose={() => setShowDeleteModal(false)}
                    handleDelete={handleDelete}
                />
            )}
        </>
    )
}

export default BranchList
