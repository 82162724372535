import React, { useState, useRef, useContext } from "react";
import { TreeItem, TreeView } from "@material-ui/lab";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ContentCut from "@mui/icons-material/ContentCut";
import ContentCopy from "@mui/icons-material/ContentCopy";
import ContentPaste from "@mui/icons-material/ContentPaste";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ProgressSpinner } from "primereact/progressspinner";
import { useLocation } from 'react-router-dom';
import configContext from "../services/congiguration/configContext";

const CustomTreeView = ({
  dataField,
  dataFunction,
  idProperty,
  labelProperty,
  addData,
  updateData,
  deleteData,
  refresh
}) => {
  const [loading, setloading] = useState(false);
  const location = useLocation();
  const [data, setData] = useState([])
  const { getApi } = useContext(configContext);

  useEffect(() => {
    const fetchData = async () => {
      setloading(true)
      const res = await getApi(location.pathname, dataFunction)
      if (res.success) {
        setData(res[dataField])
      }
      setloading(false)
    };
    fetchData();
  }, [refresh]);
  
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const [selectedNode, setSelectedNode] = useState(null);

  const handleContextMenu = (event, nodeId) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedNode(nodeId);
    setContextMenuPosition({ x: event.clientX - 290, y: event.clientY - 250 });
  };

  const handleContextMenuClose = () => {
    setContextMenuPosition({ x: 0, y: 0 });
  };

  const handleAdd = () => {
    handleContextMenuClose();
    addData(selectedNode);
  };

  const handleUpdate = () => {
    handleContextMenuClose();
    updateData(selectedNode);
  };

  const handleDelete = () => {
    handleContextMenuClose();
    deleteData(selectedNode);
  };

  const handleButtonClick = () => {
    addData("0");
  };

  const contextMenuRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        contextMenuRef.current &&
        !contextMenuRef.current.contains(event.target)
      ) {
        handleContextMenuClose();
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  if (loading) {
    return <div className="m-5 p-5 d-flex justify-content-center">
      <ProgressSpinner />
    </div>;
  }

  const renderTreeItems = (dataArray, parentId = null) => {
    const children = dataArray.filter((item) => item.parentId === parentId);

    return children.map((child) => {
      const subChildren = dataArray.filter(
        (item) => item.parentId === child[idProperty]
      );

      if (subChildren.length > 0) {
        return (
          <TreeItem
            key={child[idProperty]}
            nodeId={child[idProperty].toString()}
            label={
              <span style={{ fontSize: "24px" }}>{child[labelProperty]}</span>
            }
            onContextMenu={(event) =>
              handleContextMenu(event, child[idProperty])
            }
          >
            {renderTreeItems(dataArray, child[idProperty])}
          </TreeItem>
        );
      } else {
        return (
          <TreeItem
            key={child[idProperty]}
            nodeId={child[idProperty].toString()}
            label={
              <span style={{ fontSize: "24px" }}>{child[labelProperty]}</span>
            }
            onContextMenu={(event) =>
              handleContextMenu(event, child[idProperty])
            }
          />
        );
      }
    });
  };

  return (
    <div className="p-5">
      <Button variant="contained" onClick={handleButtonClick} className="mb-4">
        Add New
      </Button>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {data && data.length > 0 && renderTreeItems(data)}
      </TreeView>

      {/* Context Menu */}
      {contextMenuPosition.x !== 0 && (
        <Paper
          ref={contextMenuRef}
          style={{
            position: "absolute",
            top: contextMenuPosition.y,
            left: contextMenuPosition.x,
            border: "1px solid #ccc",
          }}
        >
          <MenuList>
            <MenuItem onClick={handleAdd}>
              <ListItemIcon>
                <ContentPaste fontSize="small" />
              </ListItemIcon>
              <ListItemText>Add</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleUpdate}>
              <ListItemIcon>
                <ContentCopy fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleDelete}>
              <ListItemIcon>
                <ContentCut fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </MenuList>
        </Paper>
      )}
    </div>
  );
};

export default CustomTreeView;
