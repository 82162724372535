import React from 'react'
import SPVoucher from '../SPVoucher/SPVoucher'

const SalesOrder = () => {
  return (
    <div>
      <SPVoucher Title="Sale Order voucher" Type={3}  />
    </div>
  )
}

export default SalesOrder
