import { Box, TextField, Button, MenuItem, Select, FormControl } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { InputMask } from "primereact/inputmask";
import { toast } from 'react-toastify';
import userContext from '../../../services/user/userContext';

function AddCompany(props) {
    const { addOrEdit, editRecord, modaltitle, setModalTitle } = props;
    const { currencyDD, getCurrencyDropdown } = useContext(userContext);
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); resetForm(); }
    const handleShow = () => setShow(true);

    const DefaultImage = '../../Images/DefaultImage2.png';
    const DefaultDate = new Date();
    const formattedDefaultDate = DefaultDate.toISOString().split('T')[0];
    const InitialState = {
        EmailAddress: '',
        Password: '',
        Username: '',
        CompanyId: '0',
        CompanyLogoName: '',
        CompanyAddress: '',
        CompanyLogoFile: null,
        Ntn: '',
        PhoneNumber: '',
        RegistrationDate: formattedDefaultDate,
        Expiry: '',
        CurrencyType: '',
        imageSrc: DefaultImage,
    }
    const [values, setValues] = useState(InitialState);
    useEffect(() => {
        getCurrencyDropdown();
      }, []);
    const handleImagePreview = (e) => {
        if (e.target.files && e.target.files[0]) {
            let CompanyLogoFile = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (x) => {
                setValues({
                    ...values,
                    CompanyLogoFile,
                    imageSrc: x.target.result
                })
            }
            reader.readAsDataURL(CompanyLogoFile);
        }
        else {
            setValues({
                ...values,
                CompanyLogoFile: null,
                imageSrc: DefaultImage
            })
        }
    };

    const anyValueIsUnfilled = Object.entries(values).some(([key, value]) => {
        if (key === 'CompanyLogoName' || key === 'CompanyLogoFile') {
            return false;
        }
        return value === '' || value === null;
    });

    const resetForm = () => {
        setValues(InitialState);
        document.getElementById('imageUploader').value = null;
        setShow(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!anyValueIsUnfilled) {
            const formData = new FormData();
            formData.append('CompanyId', values.CompanyId);
            formData.append('EmailAddress', values.EmailAddress);
            formData.append('Username', values.Username);
            formData.append('PhoneNumber', values.PhoneNumber);
            formData.append('Password', values.Password);
            formData.append('Ntn', values.Ntn);
            formData.append('CompanyAddress', values.CompanyAddress);
            formData.append('CompanyLogo', values.CompanyLogoName);
            formData.append('CompanyLogoFile', values.CompanyLogoFile);
            formData.append('RegistrationDate', values.RegistrationDate);
            formData.append('Expiry', values.Expiry);
            formData.append('CurrencyType', values.CurrencyType);

            addOrEdit(formData, resetForm)
        } else {
            toast.error('Please fill all required details');
        }
    };

    function formatDate(dateStr) {
        const date = new Date(dateStr);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        if (editRecord != null) {
            setValues({
                CompanyId: editRecord.companyId,
                EmailAddress: editRecord.emailAddress,
                Username: editRecord.userName,
                PhoneNumber: editRecord.phoneNumber,
                Password: '',
                Ntn: editRecord.ntn,
                CompanyAddress: editRecord.companyAddress,
                CompanyLogoName: editRecord.logo,
                CompanyLogoFile: editRecord.companyLogoFile,
                imageSrc: editRecord.logoSrc,
                RegistrationDate: formatDate(editRecord.registrationDate),
                Expiry: formatDate(editRecord.expiry),
                CurrencyType: editRecord.currencyType
            })
            setModalTitle('Edit Company')
            handleShow();
        }

    }, [editRecord]);

    const handleAddCompany = () => {
        setModalTitle('Add Company')
        handleShow();
    };
    return (
        <>
            <Button variant="contained" onClick={handleAddCompany}>
                Add Company
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{modaltitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Box component="form" onSubmit={handleSubmit}
                            noValidate autoComplete="off" >
                            <input type="hidden" value={values.CompanyId} />
                           
                            <Row>
                                <Col className='mx-3'>
                                    <Row className='mb-3'>
                                        <label htmlFor="Email">Email Address</label>
                                        <TextField type="email" value={values.EmailAddress}
                                            size='medium' onChange={(e) => setValues({ ...values, EmailAddress: e.target.value })} />
                                    </Row>
                                </Col>
                                <Col className='mx-3'>
                                    <Row className='mb-3'>
                                        <label htmlFor="Password">Password</label>
                                        <TextField type="password" value={values.Password}
                                            size='medium' onChange={(e) => setValues({ ...values, Password: e.target.value })} />
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='mx-3'>
                                    <Row className='mb-3'>
                                        <label htmlFor="Ntn">N.T.N</label>
                                        <InputMask
                                            mask="99999-9999999-9"
                                            value={values.Ntn}
                                            onChange={(e) => setValues({ ...values, Ntn: e.target.value })}

                                        >
                                            {(inputProps) => (
                                                <TextField
                                                    type="text"
                                                    value={values.Ntn}

                                                    {...inputProps}
                                                />
                                            )}
                                        </InputMask>
                                    </Row>
                                </Col>
                                <Col className='mx-3'>
                                    <Row className='mb-3'>
                                        <label htmlFor="RegistrationDate">Registration Date</label>
                                        <TextField type="date"
                                            value={values.RegistrationDate}
                                            onChange={(e) => setValues({ ...values, RegistrationDate: e.target.value })} />
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='mx-3'>
                                    <Row className='mb-3'>
                                        <label htmlFor="CompanyName">Company Name</label>
                                        <TextField type="text" value={values.Username}
                                            size='medium' onChange={(e) => setValues({ ...values, Username: e.target.value })} />
                                    </Row>
                                </Col>
                                <Col className='mx-3'>
                                    <Row className='mb-3'>
                                        <label htmlFor="CompanyName">Company Address</label>
                                        <TextField multiline maxRows={4} type="text" value={values.CompanyAddress}
                                            size='medium' onChange={(e) => setValues({ ...values, CompanyAddress: e.target.value })} />
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='mx-3'>
                                    <Row className='mb-3'>
                                        <label htmlFor="PhoneNumber">Phone Number</label>
                                        <InputMask
                                            mask="9999-9999999"
                                            value={values.PhoneNumber}
                                            onChange={(e) => setValues({ ...values, PhoneNumber: e.target.value })}

                                        >
                                            {(inputProps) => (
                                                <TextField
                                                    label="Phone Number"
                                                    type="text"
                                                    value={values.PhoneNumber}

                                                    {...inputProps}
                                                />
                                            )}
                                        </InputMask>
                                    </Row>
                                </Col>
                                <Col className='mx-3'>
                                    <Row className='mb-3'>
                                        <label htmlFor="expiry">Expiry</label>
                                        <TextField type="date"
                                            value={values.Expiry}
                                            onChange={(e) => setValues({ ...values, Expiry: e.target.value })} />
                                    </Row>
                                </Col>
                            </Row>

                            <Row className='mx-1 mb-4 center'>
                                <label htmlFor="CurrencyType">Select Currency</label>
                                <FormControl fullWidth>
                                    <Select
                                        value={values.CurrencyType}
                                        onChange={(e) => setValues({ ...values, CurrencyType: e.target.value })}
                                        placeholder='Select a Company'
                                    >
                                        {currencyDD.map((currency) => (
                                            <MenuItem key={currency.currencyId} value={currency.currencyId} >
                                                <span>{currency.countryName} - {currency.currencyCode}</span>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Row>

                            <Row className='mx-1 mb-3 center'>
                                <Button component="label" size='large' variant="outlined">
                                    <Row>
                                        <Col>
                                            <Row>
                                                <Col md={9}>
                                                    <span className='mt-1'>Upload Company Logo</span>
                                                </Col>
                                                <Col md={3}>
                                                    <img alt={values.Username} src={values.imageSrc} height={30} />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <input type="file" accept='image/*' id='imageUploader'
                                                onChange={handleImagePreview} className='form-control' />
                                        </Col>
                                    </Row>
                                </Button>
                            </Row>
                            <Row className='m-3 text-center'>
                                <Button type='submit' variant="contained">Save</Button>
                            </Row>
                        </Box>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddCompany;