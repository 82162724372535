import React from 'react'
import SCForm from './SCForm'

const Supplier = () => {
  return (
    <SCForm Title="Supplier" parentId="8" parentName="Sundry Creditors" />
  )
}

export default Supplier
