import React from 'react'
import JCVoucher from '../JCRPVoucher/JCVoucher';

const JournalVoucher = () => {
    return (
        <JCVoucher
            Type={5}
            Title="Journal Voucher"
        />
    )
}

export default JournalVoucher
