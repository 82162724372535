import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import userContext from "../../services/user/userContext";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

const AccountLogin = () => {
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [loginData, setLoginData] = useState({
    EmailAddress: "",
    Password: "",
  });
  const { login } = useContext(userContext);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await login(loginData);
      if (result) {
        setLoginData({ EmailAddress: "", Password: "" });
        setLoading(false);
        navigate("/Home");
      }
      setLoading(false);
    } catch (error) {
      console.error("Login error:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    setIsVisible(true);
  }, []);
  const LoginForm = (
    <form onSubmit={handleSubmit}>
      <label style={{ color: "#0057d3", fontSize: "1rem" }}>
        <b>Email Address</b>
      </label>
      <InputText
        className="mb-4 w-100"
        style={{ backgroundColor: "#dbebff" }}
        type="email"
        value={loginData.EmailAddress}
        placeholder="eg: joe@gmail.com"
        onChange={(e) =>
          setLoginData({ ...loginData, EmailAddress: e.target.value })
        }
      />
      <label style={{ color: "#0057d3" }}>
        <b>Password</b>
      </label>
      <InputText
        className="mb-4 w-100"
        style={{ backgroundColor: "#dbebff" }}
        type="password"
        placeholder="eg: ******"
        value={loginData.Password}
        onChange={(e) =>
          setLoginData({ ...loginData, Password: e.target.value })
        }
      />

      <div className="text-center">
        <Button
          className="my-4 px-5 w-100"
          color="dark"
          size="lg"
          type="submit"
          disabled={loading ? true : false}
        >
          <Spinner
            hidden={!loading}
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Login
        </Button>
      </div>
    </form>
  );
  return (
    <Container
      fluid
      style={{
        backgroundImage: `url("../Images/Background.jpg")`,
        backgroundSize: "cover",
      }}
    >
      <Row className="vh-100 d-flex align-items-center">
        <Col md={{ span: 4, offset: 4 }}>
          <Card
            style={{
              borderRadius: "5%",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Card.Body className="d-flex flex-column ">
              <div className="text-center mb-4">
                <img src="../Images/DefaultImage.jpg" height={60} />
              </div>
              {LoginForm}
              {/* {showFPForm ? ForgetPassForm : LoginForm}
              <span
                className="mb-4 text-center"
                style={{
                  color: "#393f81",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => setShowFPForm(!showFPForm)}
              >
                {showFPForm ? "Goto Login Page" : "Forgot password?"}
              </span> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AccountLogin;
