import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import Button from "@mui/material/Button";
import AddSC from './AddSC';
import configContext from '../../../services/congiguration/configContext';
import DeleteConfirmation from '../../basic/DeleteConfirmation';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import DataTableNew2 from '../../../listComponents/DataTableNew2';


const columns = [
  { field: 'accountCode', header: 'Account Code' },
  { field: 'accountName', header: 'Account' },
  { field: 'accountContactNumber', header: 'Contact Number' },
  { field: 'accountAddress', header: 'Address' }
];

const SCForm = ({ Title, parentId, parentName }) => {
  const { getApi, deleteApi, postApi } = useContext(configContext)

  const [show, setShow] = useState(false);
  const handleClose = () => { setShow(false); resetForm(); }
  const handleShow = () => setShow(true);
  const [editRecord, setEditRecord] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setdeleteId] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const location = useLocation();

  const initialValues = {
    accountId: "0",
    accountName: "",
    accountPrefix: "",
    accountContactNumber: "",
    accountAddress: "",
    parentId: parentId,
    accountEmailAddress: "",
    accountCode: "",
  }
  const [values, setValues] = useState(initialValues)
  useEffect(() => {
    setValues({ ...values, parentId: parentId })
  }, [])


  const handleChange = (e, field) => {
    setValues({
      ...values,
      [field]: e.target.value
    })
  };

  const resetForm = () => {
    setValues(initialValues);
    setShow(false);
  };

  const onEditClick = (data) => {
    setEditRecord(data)
  }

  const onDeleteClick = useCallback((rowData) => {
    setdeleteId(rowData.accountId);
    setShowDeleteModal(true);
  }, []);

  const handleDelete = useCallback(async () => {
    const res = await deleteApi(`Accounts/DeleteAccount?id=${deleteId}`)
    if (res.success) {
      toast.success(res.message);
      setShowDeleteModal(false);
      setdeleteId(null);
      setRefresh(!refresh)
    } else {
      toast.error(res.message);
    }

  }, [deleteApi, deleteId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (values.parentId !== '' && values.accountName !== '') {

      const formData = new FormData();
      formData.append('accountId', values.accountId);
      formData.append('accountName', values.accountName);
      formData.append('accountPrefix', values.accountPrefix === undefined ? '' : values.accountPrefix);
      formData.append('accountContactNumber', values.accountContactNumber);
      formData.append('accountAddress', values.accountAddress);
      formData.append('accountEmailAddress', values.accountEmailAddress);
      formData.append('parentId', values.parentId === "0" ? "" : values.parentId);

      const res = await postApi(location.pathname, formData, "Accounts/AddAccount");
      if (res.success) {
        toast.success(res.message);
        resetForm();
        setRefresh(!refresh)
      } else {
        toast.error(res.message);
      }

    } else {
      toast.error('Please fill all required details');
    }
  };
  return (
    <Container fluid>
      <Row className="my-3 mx-2">
        <Card>
          <Card.Header
            as="h5"
            className="d-flex justify-content-between align-items-center"
          >
            <span className="mb-0">{Title}</span>
            <Row>
              <Col>
                <AddSC
                  values={values}
                  setValues={setValues}
                  handleChange={handleChange}
                  show={show}
                  handleClose={handleClose}
                  handleShow={handleShow}
                  title={Title}
                  handleSubmit={handleSubmit}
                  parentName={parentName}
                  editRecord={editRecord}
                />
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <div className="card my-2">
              <DataTableNew2
                columns={columns}
                datafunction={`Accounts/GetAccountTable?parentId=${parentId}`}
                onEdit={onEditClick}
                onDelete={onDeleteClick}
                dataid='accountId'
                refresh={refresh}
                dataField="data"
                countField="totalRecords"
              />
            </div>
          </Card.Body>

        </Card>
      </Row>
      {showDeleteModal && (
        <DeleteConfirmation
          item='Account'
          handleClose={() => setShowDeleteModal(false)}
          handleDelete={handleDelete}
        />
      )}
    </Container>
  )
}

export default SCForm
