import { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import userContext from '../../../services/user/userContext';
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { Col, Row } from "react-bootstrap";
import { Button, TextField } from '@mui/material';
import { toast } from 'react-toastify';

const AddRole = (props) => {
    const { addOrEdit, editRecord, modaltitle, setModalTitle, setEditRecord } = props;
    const [show, setShow] = useState(false);
    const handleClose = () => {setShow(false); setEditRecord(null)}
    const handleShow = () => setShow(true);
    const { getMenu } = useContext(userContext);
    const [nodes, setNodes] = useState([]);
    const [selectedNodeKeys, setSelectedNodeKeys] = useState([]);
    const [data, setData] = useState([]);
    const initialState = {
        roleId: "0",
        role: ""
    }
    const [values, setValues] = useState(initialState);

    useEffect(() => {
        const fetchMenu = async () => {
            try {
                const menuData = await getMenu();
                const formattedData = formatMenuData(menuData);
                setNodes(formattedData);
            } catch (error) {
                console.error("Error fetching menu:", error);
            }
        };
        fetchMenu();
    }, []);

    const formatMenuData = (menuData) => {
        const rootItems = menuData.filter((item) => {
            return item.parentId === 0;
        });

        const buildTree = (items) => {
            return items.map((item) => {
                const children = menuData.filter(
                    (child) => child.parentId === item.menuId
                );

                return {
                    key: item.menuId,
                    menuText: item.menuText,
                    menuUrl: item.menuUrl,
                    children: buildTree(children),
                };
            });
        };

        return buildTree(rootItems);
    };

    const handleCheckboxChange = (nodeKey, fieldName) => (e) => {
        const updatedFormData = [...data];
        const existingDataIndex = updatedFormData.findIndex(
            (data) => data.menuId === nodeKey
        );

        if (existingDataIndex !== -1) {
            updatedFormData[existingDataIndex][fieldName] = e.target.checked;
        } else {
            updatedFormData.push({
                menuId: nodeKey,
                isAdd: fieldName === "isAdd" ? e.target.checked : false,
                isEdit: fieldName === "isEdit" ? e.target.checked : false,
                isFind: fieldName === "isFind" ? e.target.checked : false,
                isDelete: fieldName === "isDelete" ? e.target.checked : false,
            });
        }

        setData(updatedFormData);

        // Update child nodes
        const updateChildNodes = (node) => {
            node.children.forEach((child) => {
                const childDataIndex = updatedFormData.findIndex(
                    (data) => data.menuId === child.key
                );

                if (childDataIndex !== -1) {
                    updatedFormData[childDataIndex][fieldName] = e.target.checked;
                } else {
                    updatedFormData.push({
                        menuId: child.key,
                        isAdd: fieldName === "isAdd" ? e.target.checked : false,
                        isEdit: fieldName === "isEdit" ? e.target.checked : false,
                        isFind: fieldName === "isFind" ? e.target.checked : false,
                        isDelete: fieldName === "isDelete" ? e.target.checked : false,
                    });
                }

                updateChildNodes(child);
            });
        };

        const parent = nodes.find((node) => node.key === nodeKey);
        if (parent) {
            updateChildNodes(parent);
        }
    };

    const checkboxBody = (node, fieldName) => {
        const isChecked = data.some((data) => data.menuId === node.key && data[fieldName]);

        return (
            <Checkbox
                onChange={handleCheckboxChange(node.key, fieldName)}
                checked={isChecked}
            />
        );
    };

    const resetForm = () => {
        setData([]);
        setValues(initialState);
        handleClose();
        setEditRecord(null)
    };

    const handleAddRole = () => {
        setModalTitle('Add Item')
        handleShow();
    };

    const saveFormData = async () => {
        if (values.role === "") {
            toast.error("Please enter role name");
        } else {
            const updatedData = data.map((d) => {
                return {
                    ...d,
                    roleId: values.roleId
                };
            });

            const requestBody = {
                role: values.role,
                roleId: values.roleId,
                DetailArray: updatedData
            };
            addOrEdit(requestBody, resetForm);
        }
    };

    useEffect(() => {
        if (editRecord != null) {
            setValues({
                roleId: editRecord.roleId,
                role:editRecord.role,
            })
            setData(editRecord.detailArray)
            setModalTitle('Edit Item')
            handleShow();
        }

    }, [editRecord]);

    return (
        <>
            <Button variant="contained" onClick={handleAddRole}>
                Add Role
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{modaltitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input hidden value={values.roleId} readOnly />
                    <Row className='mx-3'>
                        <Col md={4}>
                            <Row className='mb-3'>
                                <TextField type="text" value={values.role} label="Role Name"
                                    size='medium' onChange={(e) => setValues({ ...values, role: e.target.value })} />
                            </Row>
                        </Col>
                        <Col md={{ span: 1, offset: 7 }}>
                            <Row className='mb-3'>
                                <Button
                                    variant="contained"
                                    onClick={saveFormData}
                                >
                                    Save
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                    <TreeTable
                        value={nodes}
                        selectionMode="checkbox"
                        selectionKeys={selectedNodeKeys}
                        onSelectionChange={(e) => setSelectedNodeKeys(e.value)}
                        tableStyle={{ minWidth: "50rem" }}
                    >
                        <Column
                            field="menuText"
                            header="Name"
                            expander
                            body={(node) => <span>{node.menuText}</span>}
                        />
                        <Column body={(node) => checkboxBody(node, "isAdd")} header="Add" />
                        <Column body={(node) => checkboxBody(node, "isEdit")} header="Update" />
                        <Column body={(node) => checkboxBody(node, "isFind")} header="Find" />
                        <Column body={(node) => checkboxBody(node, "isDelete")} header="Delete" />
                    </TreeTable>
                </Modal.Body >
            </Modal >
        </>
    )
}

export default AddRole
