import { Box, TextField, Button, Select, MenuItem, FormControl, Checkbox, FormControlLabel } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import configContext from '../../../services/congiguration/configContext';
import { InputNumber } from 'primereact/inputnumber';
import CustomAutoComplete from '../../../listComponents/CustomAutoComplete';
import CustomAutoCompleteNew from '../../../listComponents/CustomAutoCompleteNew';

function AddItem(props) {
    const { setEditRecord, addOrEdit, editRecord, modaltitle, setModalTitle } = props;

    const [show, setShow] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const handleClose = () => { setShow(false); resetForm(); }
    const handleShow = () => { setShow(true); }

    const InitialState = {
        itemId: '',
        itemName: '',
        purchasePrice: '',
        salePrice: '',
        itemGroupId: '',
    }
    const [values, setValues] = useState(InitialState);

    const resetForm = () => {
        setValues(InitialState);
        setShow(false);
        setIsChecked(false);        
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isChecked && (!values.itemName || !values.purchasePrice || !values.salePrice)) {
            toast.error('Please fill all required details');
            return;
        }

        const formData = new FormData();
        formData.append('itemId', values.itemId || 0);
        formData.append('itemName', values.itemName);
        formData.append('purchasePrice', isChecked ? values.purchasePrice : '');
        formData.append('salePrice', isChecked ? values.salePrice : '');
        formData.append('parentId', values.itemGroupId || "");
        formData.append('isTransact', isChecked);

        addOrEdit(formData, resetForm);
    };


    useEffect(() => {
        if (editRecord != null) {
            setValues({
                itemId: editRecord.itemId,
                itemName: editRecord.itemName,
                itemGroupId: editRecord.parentId,
                purchasePrice: editRecord.purchasePrice,
                salePrice: editRecord.salePrice,
            })
            setIsChecked(editRecord.isTransact)
            setModalTitle('Edit Item')
            setEditRecord("")   
            handleShow();
        }

    }, [editRecord]);

    const handleAddItem = () => {
        setModalTitle('Add Item')
        handleShow();
    };
    const handleItemGroupChange = (newValue) => {
        setValues({ ...values, itemGroupId: newValue?.itemId })
    }
    return (
        <>
            <Button variant="contained" onClick={handleAddItem}>
                Add Item
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{modaltitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Box component="form" onSubmit={handleSubmit}
                            noValidate autoComplete="off" >
                            <input type="hidden" value={values.itemId} />

                            <Row>
                                <Col>
                                    <Row className='mb-3'>
                                        <label htmlFor="itemName">Item Group Name</label>
                                        <CustomAutoCompleteNew
                                            dataFunction={"Items/GetItemGroupDD?search="}
                                            dataField="items"
                                            value={values.itemGroupId}
                                            setter={handleItemGroupChange}
                                            idProperty={'itemId'}
                                            labelProperty={'itemName'}
                                            disabled={values.itemGroupId === "0" ? true : false}

                                        />
                                    </Row>
                                </Col>
                                <Col>
                                    <Row className='mb-3'>
                                        <label htmlFor="itemName">{isChecked ? 'Item Name' : 'Item Group Name'}</label>
                                        <TextField type="text" value={values.itemName}
                                            size='medium' onChange={(e) => setValues({ ...values, itemName: e.target.value })} />
                                    </Row>
                                </Col>
                            </Row>
                            {isChecked &&
                                <Row>
                                    <Col>
                                        <Row className='mb-3'>
                                            <label htmlFor="SalePrice">Sale Price</label>
                                            <InputNumber inputId="minmaxfraction" value={values.salePrice}
                                                onValueChange={(e) => setValues({ ...values, salePrice: e.target.value })}
                                                minFractionDigits={2} maxFractionDigits={5} size='medium' />

                                        </Row>
                                    </Col>

                                    <Col>
                                        <Row className='mb-3'>
                                            <label htmlFor="purchasePrice">Purchase Price</label>
                                            <InputNumber className='text-right' inputId="minmaxfraction" value={values.purchasePrice}
                                                onValueChange={(e) => setValues({ ...values, purchasePrice: e.target.value })}
                                                minFractionDigits={2} maxFractionDigits={5} size='medium' />
                                        </Row>
                                    </Col>
                                </Row>
                            }
                            <Row className='mb-3'>
                                <FormControlLabel
                                    control={<Checkbox checked={isChecked} />}
                                    label="Enable Sales/Purchase"
                                    value={isChecked}
                                    onChange={(e) => setIsChecked(!isChecked)}
                                />
                            </Row>
                            <Row className='m-3 text-center'>
                                <Button type='submit' variant="contained">Save</Button>
                            </Row>
                        </Box>
                    </Container>
                </Modal.Body >
            </Modal >
        </>
    );
}

export default AddItem;