import React, { useContext, useState, useCallback, useEffect } from 'react';
import { Row, Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import AddItem from './AddItem';
import DeleteConfirmation from './../../basic/DeleteConfirmation';
import configContext from './../../../services/congiguration/configContext';
import CustomTreeView from '../../../listComponents/CustomTreeView';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

const ItemList = () => {
    const { postApi, putApi, getApi } = useContext(configContext);
    const [editRecord, setEditRecord] = useState(null);
    const [modaltitle, setModalTitle] = useState('Add Item');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteItemId, setdeleteItemId] = useState(null);
    const [refresh, setRefresh] = useState(false)
    const [items, setItems] = useState([])
    const location = useLocation();
    const fetchData = async ()=>{
        const data = await getApi(location.pathname, "Items/GetItems");
        console.log(data)
        if(data.success){
            setItems(data.items);
        }
    }
    useEffect(() => {
        fetchData();
    }, []);


    const addOrEdit = useCallback(async (formData, onSuccess) => {
        const data = await postApi(location.pathname, formData, "Items/AddItem");
        if (data.success) {
            setRefresh(!refresh)
            toast.success(data.message)
            onSuccess();
        } else {
            toast.error(data.message)
        }

    }, [postApi]);

    const onEditClick = (value) => {
        const data = items.find(item => item.itemId === value);
        setEditRecord(data)
    };
    const handleAdd = (value) => {
        setEditRecord({
            ...editRecord,
            parentId: value,
            itemId: "0",
            itemName: "",
            purchasePrice: "",
            salePrice: "",
            isTransact: false
        })
    }
    const onDeleteClick = useCallback((value) => {
        setdeleteItemId(value);
        setShowDeleteModal(true);
    }, []);
    const handleDelete = useCallback(async () => {
        const formData = new FormData();
        formData.append("id", deleteItemId)
        const data = await putApi(location.pathname, formData, "Items/DeleteItem")
        if (data.success) {
            setRefresh(!refresh)
            toast.success(data.message)
            setShowDeleteModal(false);
            setdeleteItemId(null);
        } else {
            toast.error(data.message)
        }

    }, [putApi, deleteItemId]);
    return (
        <>
            <Container fluid>
                <Row className='my-3 mx-2'>
                    <Card>
                        <Card.Header as='h5' className="d-flex justify-content-between align-items-center">
                            <span className="mb-0">Item Data</span>
                            <AddItem addOrEdit={addOrEdit} editRecord={editRecord} modaltitle={modaltitle}
                                setEditRecord={setEditRecord} setModalTitle={setModalTitle} />
                        </Card.Header>
                        <Card.Body>
                            <Container >
                                <Row className='my-3 mx-2'>
                                    <Card>
                                        <Card.Header as='h5'>List Of Registered Companies</Card.Header>
                                        <Card.Body>
                                            <div className="card my-2">
                                               
                                                <CustomTreeView
                                                    dataField={"items"}
                                                    dataFunction={"Items/GetItems"}
                                                    idProperty={"itemId"}
                                                    labelProperty={"itemName"}
                                                    addData={handleAdd}
                                                    updateData={onEditClick}
                                                    deleteData={onDeleteClick}
                                                    refresh={refresh}
                                                />

                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Row>

            </Container>
            {showDeleteModal && (
                <DeleteConfirmation
                    item='Item'
                    handleClose={() => setShowDeleteModal(false)}
                    handleDelete={handleDelete}
                />
            )}
        </>
    )
}

export default ItemList
