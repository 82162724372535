import { Button, TextField } from '@mui/material'
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import React from 'react'
import { Card, Table } from 'react-bootstrap'
import CustomAutoComplete from '../../listComponents/CustomAutoComplete'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import CustomAutoCompleteNew from '../../listComponents/CustomAutoCompleteNew';

const JCChild = ({
    childData,
    setChildData,
    accountsDD,
    handleAccountChange,
    CrDrs,
    handleChildAdd,
    editIndex,
    listItems,
    handleChildEdit,
    handleChildDelete,
    Type
}) => {
    return (
        <Card className='my-2' style={{ height: "500px", overflow: "scroll" }}>
            <Table responsive bordered className="text-center">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Account Code</th>
                        <th>Account Name</th>
                        {Type === 5 || Type === 6 ? (<th>Type</th>) : null}
                        <th>Amount</th>
                        <th>Narration</th>
                        <th colSpan={2}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ width: "5%" }}>
                            <span style={{ fontSize: 30 }}>#</span>
                        </td>
                        <td style={{ width: "15%" }}>
                            <TextField
                                value={childData.accountCode}
                                disabled
                                inputProps={{ style: { textAlign: "right" } }}
                            />
                        </td>
                        <td style={{ width: "20%" }}>

                            <CustomAutoCompleteNew
                                dataFunction={"Voucher/GetAccounts?Type="+Type}
                                data={accountsDD}
                                value={childData.accountId}
                                setter={handleAccountChange}
                                idProperty={'accountId'}
                                labelProperty={'accountName'}
                            />
                        </td>
                        {Type === 5 || Type === 6 ? (
                            <td style={{ width: "20%" }}>
                                <Dropdown
                                    value={childData.type}
                                    onChange={(e) => setChildData({ ...childData, type: e.value })}
                                    options={CrDrs}
                                    optionLabel="name"
                                    placeholder="Select"
                                    className="w-100"
                                    style={{height:"50px"}}
                                />
                            </td>
                        ) : null}
                        <td style={{ width: "5%" }}>
                            <InputNumber
                                value={childData.amount}
                                style={{height:"50px"}}
                                onValueChange={(e) => setChildData({ ...childData, amount: e.value })}
                            />
                        </td>
                        <td style={{ width: "35%" }}>
                            <TextField
                                className='w-100'
                                name="narration"
                                value={childData.narration}                                
                                onChange={(e) => setChildData({ ...childData, narration: e.target.value })}
                            />
                        </td>
                        <td style={{ width: "5%" }} colSpan={2}>
                            <Button
                                id="btnAdd"
                                variant="contained"
                                color="success"
                                className="mt-2"
                                onClick={handleChildAdd}
                            >
                                {editIndex === -1 ? "Add" : "Save"}
                            </Button>
                        </td>
                    </tr>
                    {listItems.map((item, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.accountCode}</td>
                            <td>{item.accountName}</td>
                            {Type === 5 || Type === 6 ? (
                                <td>{item.type.name}</td>
                            ) : null}
                            <td>{item.amount}</td>
                            <td>{item.narration}</td>
                            <td>
                                <IconButton
                                    aria-label="edit"
                                    color="primary"
                                    onClick={() => handleChildEdit(index)}
                                >
                                    <EditIcon />
                                </IconButton>
                            </td>
                            <td>
                                <IconButton
                                    aria-label="delete"
                                    color="error"
                                    onClick={() => handleChildDelete(index)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Card>
    )
}

export default JCChild
