import { Box, TextField, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

function AddUnit(props) {
  const { editRecord, postApi, refresh, setRefresh } = props;
  const location = useLocation();
  const InitialState = {
    unitId: "0",
    unitName: "",
  };

  const [values, setValues] = useState(InitialState);

  const anyValueIsUnfilled = Object.entries(values).some(([key, value]) => {
    if (key === "unitId" && value === "") {
      values.unitId = "0";
      return false;
    }
    return value === "" || value === null;
  });

  const resetForm = () => {
    setValues(InitialState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!anyValueIsUnfilled) {
      const formData = new FormData();
      formData.append("unitId", values.unitId);
      formData.append("unitName", values.unitName);
      const data = await postApi(location.pathname, formData, "Units/AddUnit");
      if (data.success) {
        toast.success(data.message);
        resetForm();
        setRefresh(!refresh);
      } else {
        toast.error(data.message);
      }
    } else {
      toast.error("Please fill all required details");
    }
  };

  useEffect(() => {
    if (editRecord != null) {
      setValues({
        unitId: editRecord.unitId,
        unitName: editRecord.unitName,
      });
    }
  }, [editRecord]);

  return (
    <>
      <Container>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
        >
          <TextField type="text" value={values.unitId} size="medium" hidden />
          <Row className="mb-3">
            <TextField
              className="my-2"
              type="text"
              label="Unit Name"
              value={values.unitName}
              size="medium"
              onChange={(e) =>
                setValues({ ...values, unitName: e.target.value })
              }
            />
          </Row>
          <Row className="m-3 text-center">
            <Button type="submit" variant="contained">
              Save
            </Button>
          </Row>
        </Box>
      </Container>
    </>
  );
}

export default AddUnit;
