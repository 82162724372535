import { MenuItem, TextField } from "@mui/material";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from 'primereact/inputnumber';

const MasterFooter = (props) => {
  const { tax, masterData, setMasterData, currency } = props;
  const [percent, setPercent] = useState(false);
  const handleAdditionalTaxChange = (e) => {
    const selectedTax = e.value.taxPercentage;
    setMasterData((prevState) => ({
      ...prevState,
      additionalTaxId: e.value,
      additionalTaxAmount: selectedTax === 0 ? 0 : masterData.subTotal * (selectedTax / 100),
    }));
  };

  const discountTypes = [
    { name: 'Percentage (%)', id: '1' },
    { name: 'Amount', id: '2' },
  ];

  const handleDiscountTypeChange = (e) => {
    setMasterData({ ...masterData, discountTypes: e.value });
    if (e.value.id === '1') { setPercent(true) }
    else { setPercent(false) }
  }
  const defaultTax = tax.find(option => option.taxName === "No tax");
  const defaultDiscount = discountTypes.find(option => option.name === "Amount");

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Row>
              <Col className="mx-3">
                <Row className="mb-3">
                  <label htmlFor="remarks">Remarks
                  </label>
                  <TextField
                    multiline
                    rows={4}
                    type="text"
                    value={masterData.remarks}
                    size="medium"
                    onChange={(e) =>
                      setMasterData({ ...masterData, remarks: e.target.value })
                    }
                  />
                </Row>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className="mb-3">
              <Col md={3} className="mt-3" style={{ textAlign: "right" }}>
                <b>
                  <label htmlFor="subTotal">Subtotal</label>
                </b>
              </Col>
              <Col>
                <div className="p-inputgroup flex-1">
                  <InputText
                    value={masterData.subTotal}
                    readOnly
                    style={{ backgroundColor: "#e5dcdc" }}
                  />
                  <span className="p-inputgroup-addon">{currency}</span>
                </div>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={3} className="mt-3" style={{ textAlign: "right" }}>
                <b>
                  <label htmlFor="subTotal">Additional Tax</label>
                </b>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Dropdown
                      value={masterData.additionalTaxId || defaultTax}
                      onChange={handleAdditionalTaxChange}
                      options={tax}
                      optionLabel="taxName"
                      className="w-full md:w-14rem"
                      style={{ width: "100%" }}
                    />
                  </Col>
                  <Col>
                    <div className="p-inputgroup flex-1">
                      <InputText
                        value={masterData.additionalTaxAmount}
                        readOnly
                        style={{ backgroundColor: "#e5dcdc" }}
                      />
                      <span className="p-inputgroup-addon">{currency}</span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={3} className="mt-3" style={{ textAlign: "right" }}>
                <b>
                  <label htmlFor="subTotal">Discount</label>
                </b>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Dropdown
                      value={masterData.discountTypes || defaultDiscount}
                      onChange={handleDiscountTypeChange}
                      options={discountTypes}
                      optionLabel="name"
                      className="w-full md:w-14rem"
                      style={{ width: "100%" }}
                    />
                  </Col>
                  <Col>
                    <div className="p-inputgroup flex-1">
                      {percent ? (
                        <InputNumber
                          value={masterData.discountPercentage}
                          onValueChange={(e) => setMasterData({ ...masterData, discountPercentage: e.value })}
                          placeholder="%"
                        />
                      ) : (null)}
                      <InputNumber
                        value={masterData.discountAmount}
                        onValueChange={(e) => setMasterData({ ...masterData, discountAmount: e.value, discountPercentage: 0 })}
                        disabled={percent}
                        style={{ backgroundColor: percent ? "#e5dcdc" : "initial" }}
                      />
                      <span className="p-inputgroup-addon">{currency}</span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={3} className="mt-3" style={{ textAlign: "right" }}>
                <b>
                  <label htmlFor="subTotal">Shipping</label>
                </b>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <div className="p-inputgroup flex-1">
                      <InputText
                        value={masterData.shippingNumber}
                        onChange={(e) => { setMasterData({ ...masterData, shippingNumber: e.target.value }) }}
                        placeholder="# XXXXXX"
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="p-inputgroup flex-1">
                      <InputNumber
                        value={masterData.shippingAmount}
                        onValueChange={(e) => {
                          setMasterData({ ...masterData, shippingAmount: e.value })
                        }}
                        style={{ textAlign: 'right' }}
                      />
                      <span className="p-inputgroup-addon">{currency}</span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={3} className="mt-3" style={{ textAlign: "right" }}>
                <b>
                  <label htmlFor="subTotal">Grand Total</label>
                </b>
              </Col>
              <Col>
                <div className="p-inputgroup flex-1">
                  <InputText
                    value={masterData.grandTotal || 0}
                    readOnly
                    style={{ backgroundColor: "#e5dcdc" }}

                  />
                  <span className="p-inputgroup-addon">{currency}</span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MasterFooter;
