import { Autocomplete, TextField } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import configContext from '../../services/congiguration/configContext'
import CustomAutoComplete from '../../listComponents/CustomAutoComplete';

const About = () => {
  const { getAccountDDAC, accounts } = useContext(configContext);
  const initial = {
    accountId: ''
  }
  const [masterData, setMasterData] = useState(initial);

  const handleChange = (val) => {
    setMasterData((prevState) => ({
      ...prevState,
      accountId: val.accountId
    }));

  };

  return (
    <CustomAutoComplete
      dataFunction={getAccountDDAC}
      data={accounts}
      value={masterData.accountId}
      setter={handleChange}
      idProperty={'accountId'}
      labelProperty={'accountName'}
    />
  )
}

export default About
