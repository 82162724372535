import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import userContext from "../../services/user/userContext";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const { logout, logo } = useContext(userContext);
  const navigate = useNavigate();
  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <>
      <Navbar bg="primary" data-bs-theme="dark" style={{ height: "10dvh" }}>
        <Container fluid>
          <Navbar.Brand as={Link} to="/Home" style={{ padding: "2%" }}>
            {logo ? (
              <img alt="logo" src={logo} height={45} />
            ) : (
              <img alt="logo" src="../images/DefaultImage.jpg" height={45} />
            )}
          </Navbar.Brand>
          <Nav>
            <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};
export default Header;
