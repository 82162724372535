import { Autocomplete, TextField } from '@mui/material'
import React, { useEffect } from 'react'

const CustomAutoComplete = ({
    dataFunction,
    data,
    value,
    setter,
    idProperty,
    labelProperty,
    ...rest
}) => {
    const handleAccountChange = (event) => {
        if (event.target.value.length > 0) {
            fetchData(event.target.value);
        }
    };
    const handleChange = (event, newValue) => {
        setter(newValue);
    }

    const fetchData = async (val) => {
        await dataFunction(val);
    };

    useEffect(() => {
        fetchData();
    }, []);
    return (

        <Autocomplete
            options={data}
            getOptionLabel={(d) => d[labelProperty] || ''}
            value={data.find((d) => d[idProperty] === value) || null}
            onInput={handleAccountChange}
            onChange={handleChange}
            renderInput={(params) => (
                <TextField {...params} />
            )}
            {...rest}
        />
    )
}

export default CustomAutoComplete
