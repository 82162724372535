import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Row } from 'react-bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import configContext from '../services/congiguration/configContext';
import { useLocation } from 'react-router-dom';

const DataTableNew = ({ datafunction, onEdit, onDelete, columns, dataid, refresh, dataField, countField }) => {
    const location = useLocation();
    const { getApi } = useContext(configContext);
    const [limit, setLimit] = useState(5);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalrecords, setTotalrecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const rows = [5, 10, 15, 20];
    const totalPages = Math.ceil(totalrecords / limit);
    const first = (pageNumber - 1) * limit + 1;
    const last = Math.min(pageNumber * limit, totalrecords);


    useEffect(() => {
        const fetchData = async () => {
            const res = await getApi(location.pathname, `${datafunction}?pageNumber=${pageNumber}&limit=${limit}&search=${searchQuery}`);
            setData(res[dataField]);
            setTotalrecords(res[countField]);
            setLoading(false);
        };

        fetchData();
    }, [pageNumber, limit, refresh, searchQuery && searchQuery.length >= 3]);

    const handlePageChange = useCallback((newPageNumber) => {
        setPageNumber(newPageNumber);
    }, []);

    const handleNextPage = useCallback(() => {
        handlePageChange(pageNumber + 1);
    }, [handlePageChange, pageNumber]);

    const handlePrevPage = useCallback(() => {
        handlePageChange(pageNumber - 1);
    }, [handlePageChange, pageNumber]);

    const handleLimitChange = useCallback((e) => {
        setLimit(parseInt(e.target.value));
    }, []);

    const handleSearch = useCallback((e) => {
        setSearchQuery(e.target.value);
    }, []);

    const handleAction = useCallback((rowData, actionCallback) => {
        actionCallback(rowData);
    }, []);

    const rowEditorBodyTemplate = useMemo(() => (rowData) => (
        <div>
            <Button
                type="button"
                rounded text
                icon="pi pi-pencil"
                onClick={() => handleAction(rowData, onEdit)}
                raised size="small"
                className='mx-2'
            />

            <Button
                type="button"
                icon="pi pi-trash"
                rounded text severity="danger"
                raised size="small"
                onClick={() => handleAction(rowData, onDelete)}
            />
        </div>
    ), [handleAction, onEdit, onDelete]);
    const header = () => (
        <div className="p-input-icon-left" style={{ marginBottom: '10px' }}>
            <i className="pi pi-search" />
            <InputText
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearch}
            />
        </div>
    );
    if (loading) {
        return (
            <div className="m-5 p-5 d-flex justify-content-center">
                <ProgressSpinner />
            </div>
        );
    }

    return (
        <>
            <DataTable value={data} header={header} editMode="row" dataKey={dataid} tableStyle={{ minWidth: '50rem' }}>
                {columns.map((col, i) => (
                    <Column key={i} field={col.field} header={col.header} sortable />
                ))}
                <Column header="Action" body={rowEditorBodyTemplate}></Column>
            </DataTable>
            <Row className="my-2">
                <div className="d-flex justify-content-center">
                    <Dropdown value={limit} onChange={handleLimitChange} options={rows} />
                    <h6 className="mt-3 mx-2">Rows</h6>
                    <Button
                        icon="pi pi-angle-left"
                        rounded
                        text
                        aria-label="Previous Page"
                        onClick={handlePrevPage}
                        disabled={pageNumber === 1}
                    />
                    <h6 className="mt-3 mx-2">
                        {first} to {last} of {totalrecords}
                    </h6>
                    <Button
                        icon="pi pi-angle-right"
                        rounded
                        text
                        aria-label="Next Page"
                        onClick={handleNextPage}
                        disabled={pageNumber === totalPages}
                    />
                </div>
            </Row>
        </>
    );
};



export default DataTableNew;
