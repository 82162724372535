import React from 'react';
import JCVoucher from '../JCRPVoucher/JCVoucher';

const ReceiptVoucher = () => {
    return (
        <JCVoucher
            Type={8}
            Title="Receipt Voucher"
        />
    );
};

export default ReceiptVoucher;
