import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Row, Container, Table, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import NumberTextField from "./NumberTextField";
import MasterFooter from "./MasterFooter";
import AddMasters from "./AddMasters";
import configContext from "../../services/congiguration/configContext";
import CustomAutoComplete from "../../listComponents/CustomAutoComplete";
import { ProgressSpinner } from "primereact/progressspinner";
import SPVoucherList from "./SPVoucherList";
import OrderVoucher from "./OrderVoucher";

const SPVoucher = ({ Type, Title }) => {
  const {
    items,
    getItemDD,
    units,
    getUnitsDD,
    accounts,
    accountsDD,
    dueDates,
    getDueDateDD,
    getInvoiceNumber,
    tax,
    getTaxDD,
    saveVoucher,
    currency,
    getCurrency,
    getAccountDDAC,
  } = useContext(configContext);
  const [loading, setLoading] = useState(true);
  const [editIndex, setEditIndex] = useState(-1);
  const [listItems, setListItems] = useState([]);
  const [editRecord, setEditRecord] = useState(null);
  const [toBeAdded, setToBeAdded] = useState(null);
  const [checked, setChecked] = useState(true);
  const DefaultDate = new Date();
  const formattedDefaultDate = DefaultDate.toISOString().split("T")[0];
  const InitialState = {
    itemId: "",
    itemName: "",
    quantity: "",
    unitId: "",
    unitName: "",
    taxId: "",
    rate: "",
    amount: "",
    taxAmount: "",
    netAmount: "",
  };
  const masterInitialState = {
    masterId: "",
    accountId: "",
    invoiceNumber: "",
    date: formattedDefaultDate,
    dueDate: "",
    dueDateId: "",
    remarks: "",
    subTotal: 0,
    additionalTaxId: "",
    additionalTaxAmount: "",
    discountAmount: "",
    discountTypes: "",
    shippingNumber: "",
    shippingAmount: "",
    grandTotal: 0,
  };

  const [childData, setChildData] = useState(InitialState);
  const [masterData, setMasterData] = useState(masterInitialState);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getItemDD();
        await getUnitsDD();
        await getDueDateDD();
        await getTaxDD();
        await getCurrency();
        await getAccountDDAC();
        const num = await getInvoiceNumber(Type);
        setMasterData({ ...masterData, invoiceNumber: num });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const calculateGrandTotal = () => {
    const subTotal = parseFloat(masterData.subTotal) || 0;
    if (subTotal !== 0) {
      const shippingAmount = masterData.shippingAmount;
      const additionalTaxAmount =
        masterData.additionalTaxId === undefined
          ? 0
          : formatInt(masterData.additionalTaxId.taxPercentage) !== 0
          ? masterData.subTotal *
            (masterData.additionalTaxId.taxPercentage / 100)
          : 0;
      let discountAmount = formatInt(masterData.discountAmount);
      if (formatInt(masterData.discountPercentage) !== 0) {
        discountAmount =
          masterData.subTotal *
          (formatInt(masterData.discountPercentage) / 100);
      }
      const grandTotal =
        subTotal +
        additionalTaxAmount +
        formatInt(shippingAmount) -
        formatInt(discountAmount);

      setMasterData((prevState) => ({
        ...prevState,
        grandTotal: formatInt(grandTotal.toFixed(2)),
        shippingAmount: shippingAmount === 0 ? "" : shippingAmount,
        additionalTaxAmount:
          additionalTaxAmount === 0 ? "" : additionalTaxAmount,
        discountAmount: discountAmount,
      }));
    } else {
      setMasterData((prevState) => ({
        ...prevState,
        additionalTaxId: "",
        additionalTaxAmount: "",
        discountAmount: "",
        discountPercentage: "",
        shippingNumber: "",
        shippingAmount: "",
        grandTotal: subTotal,
      }));
    }
  };

  useEffect(() => {
    calculateGrandTotal();
  }, [
    masterData.subTotal,
    masterData.shippingAmount,
    masterData.additionalTaxId,
    masterData.discountAmount,
    masterData.discountPercentage,
  ]);

  const calculateSubTotal = () => {
    const subTotal = listItems
      .reduce((total, item) => total + item.netAmount, 0)
      .toFixed(2);
    setMasterData({
      ...masterData,
      subTotal,
    });
  };

  useEffect(() => {
    calculateSubTotal();
  }, [listItems]);

  const formatDate = (dateString) => {
    const dateParts = dateString.split("/");
    const formattedDate = `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`;
    return formattedDate;
  };

  const formatInt = (data) => {
    const value =
      data === null || data === "" || data === undefined || isNaN(data)
        ? 0
        : parseFloat(data);
    return value;
  };

  useEffect(() => {
    if (toBeAdded) {
      setLoading(true);
      let num = "";
      resetFormWithoutInvoice();
      setListItems(toBeAdded.childData);
      const dataFetch = async () => {
        num = await getInvoiceNumber(Type);
        setMasterData({
          masterId: formatInt(""),
          invoiceNumber: num,
          accountId: accounts.find((a) => a.accountId === toBeAdded.accountId)
            .accountId,
          voucherTypeId: Type,
          date: formatDate(toBeAdded.date),
          dueDate: formatDate(toBeAdded.dueDate),
          dueDateId: toBeAdded.dueDateId,
          remarks: toBeAdded.remarks,
          subTotal: toBeAdded.subTotal,
          additionalTaxId:
            toBeAdded.additionalTaxId === null
              ? ""
              : tax.find((t) => t.taxId === toBeAdded.additionalTaxId),
          additionalTaxAmount: formatInt(toBeAdded.additionalTaxAmount),
          discountAmount: formatInt(toBeAdded.discount),
          discountPercentage: formatInt(
            (toBeAdded.discount / toBeAdded.subTotal) * 100
          ),
          shippingNumber: toBeAdded.shippingNumber,
          shippingAmount: formatInt(toBeAdded.shippingAmount),
          grandTotal: toBeAdded.grandTotal,
        });
      };
      dataFetch();
      setLoading(false);
    }
  }, [toBeAdded]);

  useEffect(() => {
    if (editRecord) {
      resetFormWithoutInvoice();
      setListItems(editRecord.childData);
      setMasterData({
        masterId: formatInt(editRecord.masterId),
        invoiceNumber: editRecord.invoiceNumber,
        accountId: accountsDD.find((a) => a.accountId === editRecord.accountId)
          .accountId,
        voucherTypeId: Type,
        date: formatDate(editRecord.date),
        dueDate: formatDate(editRecord.dueDate),
        dueDateId: editRecord.dueDateId,
        remarks: editRecord.remarks,
        subTotal: editRecord.subTotal,
        additionalTaxId:
          editRecord.additionalTaxId === 0
            ? ""
            : tax.find((t) => t.taxId === editRecord.additionalTaxId),
        additionalTaxAmount: formatInt(editRecord.additionalTaxAmount),
        discountAmount: formatInt(editRecord.discount),
        discountPercentage: formatInt(
          (editRecord.discount / editRecord.subTotal) * 100
        ),
        shippingNumber: editRecord.shippingNumber,
        shippingAmount: formatInt(editRecord.shippingAmount),
        grandTotal: editRecord.grandTotal,
      });
    }
  }, [editRecord]);

  if (loading) {
    return (
      <div className="m-5 p-5 d-flex justify-content-center">
        <ProgressSpinner />
      </div>
    );
  }

  const calculateAmount = () => {
    const selectedItem = items.find((item) => item.itemId === childData.itemId);
    let amount = 0;

    if (selectedItem) {
      const rate = selectedItem
        ? Type === 1
          ? selectedItem.salePrice
          : selectedItem.purchasePrice
        : 0;
      amount = childData.quantity * rate;
    }

    setChildData((prevState) => ({
      ...prevState,
      rate: selectedItem
        ? Type === 1
          ? selectedItem.salePrice
          : selectedItem.purchasePrice
        : 0,
      amount: amount,
    }));
  };

  const handleQuantityChange = (e) => {
    const { value } = e.target;
    const isValidInput = /^\d*$/.test(value);

    if (isValidInput) {
      setChildData((prevState) => ({
        ...prevState,
        quantity: value,
      }));
    }
  };

  const handleItemChange = (newValue) => {
    setChildData((prevState) => ({
      ...prevState,
      itemId: newValue.itemId || "",
      itemName: newValue.itemName || "",
    }));
    const quantityInput = document.getElementById("quantityInput");
    if (quantityInput) {
      quantityInput.focus();
    }
  };

  const handleUnitChange = (newValue) => {
    setChildData((prevState) => ({
      ...prevState,
      unitId: newValue.unitId || "",
      unitName: newValue.unitName || "",
    }));
    const taxSelect = document.getElementById("taxSelect");
    if (taxSelect) {
      taxSelect.focus();
    }
    calculateAmount(childData.itemId, childData.quantity);
  };

  const handleTaxChange = (newValue) => {
    const percent = newValue.taxPercentage;
    setChildData((prevState) => ({
      ...prevState,
      taxId: newValue.taxId,
      taxName: newValue.taxName,
      taxAmount: (childData.amount * percent) / 100,
      netAmount: childData.amount + (childData.amount * percent) / 100,
    }));

    const btnAdd = document.getElementById("btnAdd");
    if (btnAdd) {
      btnAdd.focus();
    }
  };

  const handleChildEdit = (index) => {
    setEditIndex(index);
    const selectedItem = listItems[index];
    setChildData({
      itemId: selectedItem.itemId,
      itemName: selectedItem.itemName,
      quantity: selectedItem.quantity,
      unitId: selectedItem.unitId,
      unitName: selectedItem.unitName,
      rate: selectedItem.rate,
      amount: selectedItem.amount,
      taxId: selectedItem.taxId,
      taxPercentage: selectedItem.taxPercentage,
      taxName: selectedItem.taxName,
      taxAmount: selectedItem.taxAmount,
      netAmount: selectedItem.netAmount,
    });
  };

  const handleChildAdd = () => {
    if (editIndex === -1) {
      if (!anyValueIsUnfilled()) {
        const newItem = {
          itemId: childData.itemId,
          itemName: childData.itemName,
          quantity: childData.quantity,
          unitId: childData.unitId,
          unitName: childData.unitName,
          rate: childData.rate,
          amount: childData.amount,
          taxId: childData.taxId,
          taxName: childData.taxName,
          taxAmount: childData.taxAmount,
          netAmount: childData.netAmount,
        };
        setListItems((prevState) => [...prevState, newItem]);
        setChildData(InitialState);
      } else {
        toast.error("Please fill Item and quantity!");
      }
    } else {
      // Update the item at the editIndex with the new childData
      const updatedListItems = [...listItems];
      updatedListItems[editIndex] = {
        itemId: childData.itemId,
        itemName: childData.itemName,
        quantity: childData.quantity,
        unitId: childData.unitId,
        unitName: childData.unitName,
        rate: childData.rate,
        amount: childData.amount,
        taxId: childData.taxId,
        taxName: childData.taxName,
        taxAmount: childData.taxAmount,
        netAmount: childData.netAmount,
      };
      setListItems(updatedListItems);
      setEditIndex(-1);
      setChildData(InitialState);
    }
    setTimeout(() => {
      const itemInput = document.getElementById("itemInput");
      if (itemInput) {
        itemInput.focus();
      }
    }, 100);
  };

  const anyValueIsUnfilled = () => {
    return Object.entries(childData).some(([key, value]) => {
      if (key === "taxPercentage" || key === "taxAmount") {
        return false;
      }
      return value === "" || value === null || value === 0;
    });
  };

  const handleChildDelete = (index) => {
    const updatedListItems = [...listItems];
    updatedListItems.splice(index, 1);
    setListItems(updatedListItems);
  };

  const anyMasterValueIsUnfilled = () => {
    return Object.entries(masterData).some(([key, value]) => {
      if (
        key === "masterId" ||
        key === "remarks" ||
        key === "subTotal" ||
        key === "discountTypes" ||
        key === "additionalTaxId" ||
        key === "additionalTaxAmount" ||
        key === "discountAmount" ||
        key === "discountPercentage" ||
        key === "shippingNumber" ||
        key === "shippingAmount" ||
        key === "dueDateId"
      ) {
        return false;
      }
      return value === "" || value === null || value === 0;
    });
  };

  const resetForm = () => {
    const fetch = async () => {
      const num = await getInvoiceNumber(Type);
      setMasterData({ ...masterInitialState, invoiceNumber: num });
    };

    setChildData(InitialState);
    setListItems([]);
    setChecked(true);
    setEditRecord(null);
    fetch();
  };
  const resetFormWithoutInvoice = () => {
    setMasterData(masterInitialState);
    setChildData(InitialState);
    setListItems([]);
    setChecked(true);
  };

  const handleSaveVoucher = async () => {
    setLoading(true);
    if (!anyMasterValueIsUnfilled()) {
      if (listItems.length > 0) {
        const childData = listItems.map((item) => ({
          childId: item.ChildId === undefined ? 0 : item.ChildId,
          masterId: item.MasterId === undefined ? 0 : item.MasterId,
          itemId: item.itemId,
          unitId: item.unitId,
          quantity: item.quantity,
          taxId: item.taxId,
          netAmount: item.netAmount,
          rate: item.rate,
          amount: item.amount,
          taxAmount: item.taxAmount,
        }));
        console.log(masterData);
        const masterForm = {
          masterId: masterData.masterId === "" ? 0 : masterData.masterId,
          invoiceNumber: masterData.invoiceNumber,
          accountId: masterData.accountId,
          voucherTypeId: Type,
          date: masterData.date,
          dueDate: masterData.dueDate,
          dueDateId: masterData.dueDateId === "" ? 0 : masterData.dueDateId,
          remarks: masterData.remarks,
          subTotal: masterData.subTotal,
          additionalTaxId:
            masterData.additionalTaxId.taxId === undefined
              ? 0
              : masterData.additionalTaxId.taxId,
          additionalTaxAmount: parseInt(masterData.additionalTaxAmount),
          discount: parseInt(masterData.discountAmount),
          shippingNumber: masterData.shippingNumber,
          shippingAmount: parseInt(masterData.shippingAmount),
          grandTotal: masterData.grandTotal,
          childData: childData,
        };

        const data = await saveVoucher(masterForm);
        if (data) {
          setLoading(false);
          resetForm();
        }
      } else {
        setLoading(false);
        toast.error("No Item!");
      }
    } else {
      setLoading(false);
      toast.error("PLease fill required fields!");
    }
    setLoading(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const unitInput = document.getElementById("unitInput");
      if (unitInput) {
        unitInput.focus();
      }
    }
  };

  return (
    <Container fluid>
      <Row className="my-3 mx-2">
        <Card>
          <Card.Header
            as="h5"
            className="d-flex justify-content-between align-items-center"
          >
            <span className="mb-0">{Title}</span>
            <Row>
              {Type === 1 || Type === 2 ? (
                <Col md={3}>
                  <OrderVoucher
                    setToBeAdded={setToBeAdded}
                    Type={Type === 1 ? 3 : 4}
                    name="Order"
                  />
                </Col>
              ) : null}
              <Col md={Type === 1 || Type === 2 ? 3 : 4}>
                {editRecord || toBeAdded ? (
                  <Button variant="contained" onClick={resetForm}>
                    New
                  </Button>
                ) : (
                  <SPVoucherList
                    setEditRecord={setEditRecord}
                    Type={Type}
                    name="List"
                  />
                )}
              </Col>

              <Col md={Type === 1 || Type === 2 ? 6 : 8}>
                <Button variant="contained" onClick={handleSaveVoucher}>
                  Save Voucher
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <AddMasters
              checked={checked}
              setChecked={setChecked}
              masterData={masterData}
              accounts={accountsDD}
              dueDates={dueDates}
              setMasterData={setMasterData}
            />
            <Container>
              <Card style={{ height: "500px", overflow: "scroll" }}>
                <Table responsive bordered className="text-center">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Item Name</th>
                      <th>Quantity</th>
                      <th>Unit</th>
                      <th>Rate</th>
                      <th>Amount</th>
                      <th>Tax type</th>
                      <th>Tax Amount</th>
                      <th>Net Amount</th>
                      <th colSpan={2}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ width: "4%" }}>
                        <span style={{ fontSize: 30 }}>#</span>
                      </td>
                      <td style={{ width: "11%" }}>
                        <CustomAutoComplete
                          dataFunction={getItemDD}
                          data={items}
                          value={childData.itemId}
                          setter={handleItemChange}
                          idProperty={"itemId"}
                          labelProperty={"itemName"}
                          id="itemInput"
                        />
                      </td>
                      <td style={{ width: "9%" }}>
                        <TextField
                          id="quantityInput"
                          name="quantity"
                          value={childData.quantity}
                          onChange={handleQuantityChange}
                          onKeyDown={handleKeyDown}
                          inputProps={{ style: { textAlign: "right" } }}
                        />
                      </td>
                      <td style={{ width: "11%" }}>
                        <CustomAutoComplete
                          dataFunction={getUnitsDD}
                          data={units}
                          value={childData.unitId}
                          setter={handleUnitChange}
                          idProperty={"unitId"}
                          labelProperty={"unitName"}
                          id="unitInput"
                        />
                      </td>
                      <td style={{ width: "11%" }}>
                        <NumberTextField
                          name="rate"
                          value={childData.rate}
                          disabled={true}
                          inputProps={{ style: { textAlign: "right" } }}
                        />
                      </td>
                      <td style={{ width: "11%" }}>
                        <NumberTextField
                          name="amount"
                          value={childData.amount}
                          disabled={true}
                          inputProps={{ style: { textAlign: "right" } }}
                        />
                      </td>
                      <td style={{ width: "11%" }}>
                        <CustomAutoComplete
                          dataFunction={getTaxDD}
                          data={tax}
                          value={childData.taxId}
                          setter={handleTaxChange}
                          idProperty={"taxId"}
                          labelProperty={"taxName"}
                          id="taxSelect"
                        />
                      </td>
                      <td style={{ width: "9%" }}>
                        <NumberTextField
                          name="taxAmount"
                          value={childData.taxAmount}
                          disabled={true}
                          inputProps={{ style: { textAlign: "right" } }}
                        />
                      </td>
                      <td style={{ width: "12%" }}>
                        <NumberTextField
                          name="netAmount"
                          value={childData.netAmount}
                          disabled={true}
                          inputProps={{ style: { textAlign: "right" } }}
                        />
                      </td>
                      <td style={{ width: "8%" }} colSpan={2}>
                        <Button
                          id="btnAdd"
                          variant="contained"
                          color="success"
                          className="mt-2"
                          onClick={handleChildAdd}
                        >
                          {editIndex === -1 ? "Add" : "Save"}
                        </Button>
                      </td>
                    </tr>
                    {listItems.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.itemName}</td>
                        <td>{item.quantity}</td>
                        <td>{item.unitName}</td>
                        <td>{item.rate}</td>
                        <td>{item.amount}</td>
                        <td>{item.taxName}</td>
                        <td>{item.taxAmount}</td>
                        <td>{item.netAmount}</td>
                        <td>
                          <IconButton
                            aria-label="edit"
                            color="primary"
                            onClick={() => handleChildEdit(index)}
                          >
                            <EditIcon />
                          </IconButton>
                        </td>
                        <td>
                          <IconButton
                            aria-label="delete"
                            color="error"
                            onClick={() => handleChildDelete(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Container>
          </Card.Body>
          <Card.Body>
            <MasterFooter
              masterData={masterData}
              tax={tax}
              setMasterData={setMasterData}
              currency={currency}
            />
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
};

export default SPVoucher;
