import React, { useCallback, useContext, useState } from 'react';
import { Row, Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { toast } from 'react-toastify';
import DeleteConfirmation from './../../basic/DeleteConfirmation';
import userContext from '../../../services/user/userContext';
import Pagination from '../../../listComponents/Pagination';
import AddUser from './AddUser';

const UserList = () => {
    const { getUsers, users, editUser, deleteUser, registerUser, totalRecords } = useContext(userContext);
    const [editRecord, setEditRecord] = useState(null);
    const [modaltitle, setModalTitle] = useState('Add User');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);

    const columns = [
        { field: 'companyUserName', header: 'Company Name' },
        { field: 'branchUserName', header: 'Branch Name' },
        { field: 'userName', header: 'UserName' },
        { field: 'phoneNumber', header: 'Phone Number' }
    ];

    const addOrEdit =  useCallback(async (formData, onSuccess) => {
        if (formData.get('UserId') === "0") {
            const data = await registerUser(formData);
            if (data) {
                onSuccess();
            }
        }
        else {
            const data = await editUser(formData.get('UserId'), formData);
            if (data) {
                toast.success(data.message);
                onSuccess();
            }
            else {
                toast.error(data.message);
            }
        }
    },[registerUser, editUser]);

    const onEditClick = (rowData) => {
        setEditRecord(rowData);
    };

    const onDeleteClick = useCallback((rowData) => {
        setDeleteItemId(rowData.accountId);
        setShowDeleteModal(true);
    }, []);

    const handleDelete = async () => {
        const data = await deleteUser(deleteItemId);
        setShowDeleteModal(false);
        setDeleteItemId(null);
    };

    return (
        <>
            <Container fluid>
                <Row className='my-3 mx-2'>
                    <Card>
                        <Card.Header as='h5' className="d-flex justify-content-between align-items-center">
                            <span className="mb-0">User Data</span>
                            <AddUser addOrEdit={addOrEdit} editRecord={editRecord} modaltitle={modaltitle}
                                setModalTitle={setModalTitle} />
                        </Card.Header>
                        <Card.Body>
                            <Container >
                                <Row className='my-3 mx-2'>
                                    <Card>
                                        <Card.Header as='h5'>List Of Registered Users</Card.Header>
                                        <Card.Body>
                                            <div className="card my-2">
                                                <Pagination
                                                    data={users}
                                                    columns={columns}
                                                    datafunction={getUsers}
                                                    totalrecords={totalRecords}
                                                    onEdit={onEditClick}
                                                    onDelete={onDeleteClick}
                                                    dataid='userId'
                                                    image={true}
                                                />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Row>

            </Container>
            {showDeleteModal && (
                <DeleteConfirmation
                    item='Branch'
                    handleClose={() => setShowDeleteModal(false)}
                    handleDelete={handleDelete}
                />
            )}
        </>
    )
}

export default UserList
