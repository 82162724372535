import React from 'react';
import SPVoucher from '../SPVoucher/SPVoucher';

const AddSale = () => {
  return (
    <div>
      <SPVoucher Title="Sale voucher" Type={1} />
    </div>
  )
}

export default AddSale
