import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Row } from 'react-bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';

const Pagination = ({ datafunction, data, totalrecords, onEdit, onDelete, columns, dataid, image, Type }) => {
  const [limit, setLimit] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const totalPages = Math.ceil(totalrecords / limit);
  const first = (pageNumber - 1) * limit + 1;
  const last = Math.min(pageNumber * limit, totalrecords);
  const [loading, setLoading] = useState(true);

  const [searchQuery, setSearchQuery] = useState("");
  const rows = [5, 10, 15, 20];

  useEffect(() => {
    const fetchData = async () => {
      if (Type === undefined) {
        await datafunction(pageNumber, limit, searchQuery);
        setLoading(false);
      } else {
        datafunction(Type, pageNumber, limit, searchQuery);
        setLoading(false);
      }
    }
    fetchData();
  }, [pageNumber, limit, searchQuery.length >= 3 || searchQuery.length === 0]);

  const handlePageChange = useCallback((newPageNumber) => {
    setPageNumber(newPageNumber);
  }, []);

  const handleNextPage = useCallback(() => {
    handlePageChange(pageNumber + 1);
  }, [handlePageChange, pageNumber]);

  const handlePrevPage = useCallback(() => {
    handlePageChange(pageNumber - 1);
  }, [handlePageChange, pageNumber]);

  const handleLimitChange = useCallback((e) => {
    setLimit(parseInt(e.target.value));
  }, []);



  const LogoBodyTemplate = useMemo(() => (rowData) => {
    const logo = rowData.logo;
    return (
      <div className="flex align-items-center gap-2">
        <img alt={logo} src={rowData.logoSrc} height={30} />
      </div>
    );
  }, []);

  const rowEditorBodyTemplate = useMemo(() => (rowData) => {
    return (
      <div>
        <Button
          type="button"
          rounded text
          icon="pi pi-pencil"
          onClick={() => onEditClick(rowData)}
          raised size="small"
          className='mx-2'
        />

        <Button
          type="button"
          icon="pi pi-trash"
          rounded text severity="danger"
          raised size="small"
          onClick={() => onDeleteClick(rowData)}
        />
      </div>
    );
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  const header = () => {
    return (
      <div className="p-input-icon-left" style={{ marginBottom: '10px' }}>
        <i className="pi pi-search" />
        <InputText
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>
    );
  };
  const onDeleteClick = (rowData) => {
    onDelete(rowData);
  }
  const onEditClick = (rowData) => {
    onEdit(rowData);
  }
  if (loading) {
    return <div className="m-5 p-5 d-flex justify-content-center">
      <ProgressSpinner />
    </div>;
  }
  return (
    <>
      <DataTable value={data} header={header} editMode="row"
        dataKey={dataid} tableStyle={{ minWidth: '50rem' }}>
        {image ? (
          <Column header="Logo" body={LogoBodyTemplate} />
        ) : (<></>)}
        {columns.map((col, i) => (
          <Column key={i} field={col.field} header={col.header} sortable />
        ))}
        <Column header="Action" body={rowEditorBodyTemplate}></Column>
      </DataTable>
      <Row className="my-2">
        <div className="d-flex justify-content-center">
          <Dropdown value={limit} onChange={handleLimitChange} options={rows} />
          <h6 className="mt-3 mx-2">Rows</h6>
          <Button
            icon="pi pi-angle-left"
            rounded
            text
            aria-label="Previous Page"
            onClick={handlePrevPage}
            disabled={pageNumber === 1}
          />
          <h6 className="mt-3 mx-2">
            {first} to {last} of {totalrecords}
          </h6>
          <Button
            icon="pi pi-angle-right"
            rounded
            text
            aria-label="Next Page"
            onClick={handleNextPage}
            disabled={pageNumber === totalPages}
          />
        </div>
      </Row>
    </>
  );
};

export default Pagination;