import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useContext } from 'react';
import configContext from '../services/congiguration/configContext';
import { useEffect } from 'react';

export default function SearchBar() {
  const { units, getUnits } = useContext(configContext)
  useEffect(() => {
    getUnits();

  }, [units])

  return (
    <Autocomplete
      options={units.map(unit => unit.unitName)}
      renderInput={(params) => <TextField {...params} label="Search" 
      />}
    />
    // <Autocomplete
    //   disablePortal
    //   id="combo-box-demo"
    //   options={units}
    //   sx={{ width: 300 }}
    //   renderInput={(params) => <TextField {...params} label="Movie" />}
    // />
  );
}
