import React, { useContext, useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { Autocomplete, Checkbox, FormControlLabel, MenuItem, TextField } from '@mui/material';
import configContext from '../../services/congiguration/configContext';
import CustomAutoComplete from '../../listComponents/CustomAutoComplete';

const AddMasters = (props) => {
    const { masterData, setMasterData, accounts, dueDates, checked, setChecked } = props
    const { getAccountDDAC } = useContext(configContext)
    const handleAccountChange = (newValue) => {
        setMasterData((prevState) => ({
            ...prevState,
            accountId: newValue?.accountId || '',
            accountName: newValue?.accountName || '',
        }));
    };

    const handleDateChange = (e) => {
        setMasterData({ ...masterData, date: e.target.value })
        const account = document.getElementById('account');
        if (account) {
            account.focus();
        }
    };

    const handleDueDateChange = (e) => {
        const selectedDaysId = e.target.value;
        const selectedDays = dueDates.find((date) => date.daysId === selectedDaysId);

        if (selectedDays) {
            const currentDate = new Date(masterData.date);
            currentDate.setDate(currentDate.getDate() + selectedDays.days);
            const formattedDueDate = currentDate.toISOString().substr(0, 10); // Format to 'yyyy-mm-dd'
            setMasterData({ ...masterData, dueDateId: selectedDaysId, dueDate: formattedDueDate });
            console.log(formattedDueDate);
        }
    };

    return (
        <>
            <Container className='mb-5'>
                <Card>
                    <input type="hidden" value={masterData.masterId} />
                    <Row className='mx-3 mt-3'>
                        <Col className='mx-3'>
                            <Row className='mb-3'>
                                <label htmlFor="invoiceNumber">Invoice Number</label>
                                <TextField
                                    disabled
                                    type="text"
                                    value={masterData.invoiceNumber}
                                    size='medium'
                                />
                            </Row>
                        </Col>

                        <Col className='mx-3'>
                            <Row className='mb-3'>
                                <label htmlFor="date">Date</label>
                                <TextField
                                    type="date"
                                    value={masterData.date}
                                    size='medium'
                                    onChange={handleDateChange}
                                />
                            </Row>
                        </Col>
                    </Row>
                    <Row className='mx-2'>
                        <Col className='mx-3'>
                            <Row className='mb-3'>
                                <label htmlFor="accountId">Account</label>
                                <CustomAutoComplete
                                    dataFunction={getAccountDDAC}
                                    data={accounts}
                                    value={masterData.accountId}
                                    setter={handleAccountChange}
                                    idProperty={'accountId'}
                                    labelProperty={'accountName'}
                                />
                            </Row>
                        </Col>

                        <Col className='mx-3'>
                            <Row className='mb-3'>
                                <label htmlFor="dueDate">Due Date</label>
                                {checked ?
                                    <TextField
                                        value={masterData.dueDate}
                                        size='medium'
                                        type="date"
                                        id="dueDate"
                                        onChange={(e) => setMasterData({ ...masterData, dueDate: e.target.value })}
                                    />
                                    :
                                    <TextField
                                        value={masterData.dueDateId}
                                        size='medium'
                                        type={checked ? "date" : ""}
                                        id="dueDate"
                                        onChange={handleDueDateChange}
                                        select={!checked}
                                    >
                                        {dueDates.map((date) => (
                                            <MenuItem key={date.daysId} value={date.daysId}>{date.days} Days</MenuItem>
                                        ))}
                                    </TextField>
                                }
                                <FormControlLabel
                                    control={<Checkbox checked={checked} />}
                                    label="Select Date"
                                    checked={checked}
                                    onChange={(e) => setChecked(e.target.checked)}
                                />
                            </Row>
                        </Col>
                    </Row>

                </Card>
            </Container>
        </>
    )
}

export default AddMasters
