import React from 'react';
import JCVoucher from '../JCRPVoucher/JCVoucher';

const ContraVoucher = () => {
    return (
        <JCVoucher
            Type={6}
            Title="Contra Voucher"
        />
    );
};

export default ContraVoucher;
