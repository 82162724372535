import React, { useContext, useState, useCallback } from 'react';
import { Row, Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import AddCompany from './AddCompany';
import { toast } from 'react-toastify';
import DeleteConfirmation from './../../basic/DeleteConfirmation';
import userContext from '../../../services/user/userContext';
import Pagination from '../../../listComponents/Pagination';

const CompanyList = () => {
    const { companies, getCompanies, registerCompany, editCompany, DeleteCompany, totalRecords } = useContext(userContext);
    const [editRecord, setEditRecord] = useState(null);
    const [modaltitle, setModalTitle] = useState('Add Company');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const columns = [
        { field: 'userName', header: 'Company Name' },
        { field: 'ntn', header: 'N.T.N' },
        { field: 'expiry', header: 'Expiry' },
        { field: 'companyAddress', header: 'Company Address' }
    ];


    const addOrEdit = useCallback(async (formData, onSuccess) => {
        if (formData.get('CompanyId') === "0") {
            const data = await registerCompany(formData);
            if (data) {
                onSuccess();
            }
        }
        else {
            const data = await editCompany(formData.get('CompanyId'), formData);
            if (data) {
                onSuccess();
            }
        }
    }, [registerCompany, editCompany]);
    const onEditClick = (rowData) => {
        setEditRecord(rowData);
    };
    const onDeleteClick = useCallback((rowData) => {
        setDeleteItemId(rowData.companyId);
        setShowDeleteModal(true);
      }, []);
    const handleDelete = useCallback(async () => {
        await DeleteCompany(deleteItemId);        
        setShowDeleteModal(false);
        setDeleteItemId(null);
    }, [DeleteCompany, deleteItemId]);
    return (
        <>
            <Container fluid>
                <Row className='my-3 mx-2'>
                    <Card>
                        <Card.Header as='h5' className="d-flex justify-content-between align-items-center">
                            <span className="mb-0">Company Data</span>
                            <AddCompany addOrEdit={addOrEdit} editRecord={editRecord} modaltitle={modaltitle}
                                setModalTitle={setModalTitle} />
                        </Card.Header>
                        <Card.Body>
                            <Container >
                                <Row className='my-3 mx-2'>
                                    <Card>
                                        <Card.Header as='h5'>List Of Registered Companies</Card.Header>
                                        <Card.Body>
                                            <div className="card my-2">
                                                <Pagination
                                                    data={companies}
                                                    columns={columns}
                                                    datafunction={getCompanies}
                                                    totalrecords={totalRecords}
                                                    onEdit={onEditClick}
                                                    onDelete={onDeleteClick}
                                                    dataid='companyId'
                                                    image={true}
                                                />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Row>

            </Container>
            {showDeleteModal && (
                <DeleteConfirmation
                    item='Company'
                    handleClose={() => setShowDeleteModal(false)}
                    handleDelete={handleDelete}
                />
            )}
        </>
    )
}

export default CompanyList
